import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Badge,
  Card,
  Table,
  HoverCard,
  Alert,
  Text,
  Button,
  AppShell,
  Modal,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconInfoCircle, IconAt } from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import { getFinancialValueFromNumeric, getFullDate } from "../../Lib/Methods";

export default function SinglePatient() {
  useEffect(() => {
    nprogress.start();
    document.title = "Patient Details";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const transactions = [
    {
      date: "22-03-2021",
      amount: 23234,

      status: "Pending",
      name: "Deandre Yedlin",
    },
    {
      date: "22-03-2021",
      amount: 34232,

      status: "Failed",

      name: "Arjen Robben",
    },
    {
      date: "22-03-2021",
      amount: 456562,

      status: "Pending",

      name: "Catelyn Stark",
    },
    {
      date: "23-03-2021",
      amount: 562,

      status: "Pending",

      name: "Tyrion Lannister",
    },
    {
      date: "23-03-2021",
      amount: 6562,

      status: "Success",

      name: "James St Patrick",
    },
    {
      date: "23-03-2021",
      amount: 3422,

      status: "Pending",

      name: "Homelander",
    },
    {
      date: "23-03-2021",
      amount: 7452,

      status: "Success",

      name: "Mo Salah",
    },
    {
      date: "23-03-2021",
      amount: 46542,

      status: "Success",

      name: "Kitan Niminums",
    },
  ];

  return (
    <AppShell padding="lg">
      <Container maxWidth="lg" className="vendors-container">
        <div className="flex-row align-center justify-between width-100">
          <Text fw={500} size="20px">
            Patient Profile
          </Text>
        </div>
        <Card withBorder mt={10}>
          <div className="flex-row width-100 align-start justify-between">
            <Table
              style={{
                width: "500px",
              }}
              withRowBorders={false}
              withColumnBorders={false}
              withTableBorder={false}
            >
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>
                    <Text size="17px" c="dimmed">
                      Full Name:
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="17px" fw={500}>
                      Jon Joe Shelvy
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <Text size="17px" c="dimmed">
                      Email:
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="17px" fw={500}>
                      me@theajstars.com
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <Text size="17px" c="dimmed">
                      Phone:
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="17px" fw={500}>
                      08029015845
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <Text size="17px" c="dimmed">
                      Nationality:
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="17px" fw={500}>
                      Nigerian
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <Text size="17px" c="dimmed">
                      Address:
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="17px" fw={500}>
                      20 MLK Estate, Lekki, Lagos
                    </Text>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
            <div className="flex-col">
              <Button
                color="red"
                style={{
                  width: "200px",
                }}
              >
                Deactivate Account
              </Button>

              <HoverCard width={280} shadow="md">
                <HoverCard.Target>
                  <IconInfoCircle color="green" style={{ marginTop: "10px" }} />
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">
                    Deactivating a patient account will
                    <br />
                    prevent them from logging in
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </div>
          </div>
        </Card>
        <br />
        <Text fw={500} size="20px">
          Patient Transactions
        </Text>
        <Table verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Date</Table.Th>
              <Table.Th>Amount</Table.Th>
              <Table.Th>Patient</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Details</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {transactions.map((transaction, index) => {
              return (
                <Table.Tr key={index}>
                  <Table.Td>{getFullDate(Date.now())}</Table.Td>
                  <Table.Td>
                    <Text c="blue">
                      ₦{getFinancialValueFromNumeric(transaction.amount)}
                    </Text>
                  </Table.Td>
                  <Table.Td>{transaction.name}</Table.Td>
                  <Table.Td>
                    {transaction.status === "Pending" ? (
                      <Badge color="orange">{transaction.status}</Badge>
                    ) : transaction.status === "Success" ? (
                      <Badge color="green">{transaction.status}</Badge>
                    ) : (
                      <Badge color="red">{transaction.status}</Badge>
                    )}
                  </Table.Td>
                  <Table.Td>
                    <Link to={`/dashboard/transaction/${transaction.amount}`}>
                      <Button variant="subtle">View Details</Button>
                    </Link>
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
        <br />
      </Container>
    </AppShell>
  );
}
