import { useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import {
  Avatar,
  Text,
  Menu,
  NavLink,
  ActionIcon,
  AppShell,
} from "@mantine/core";

import {
  IconLogout2,
  IconMenu2,
  IconX,
  IconUserCircle,
} from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import { AppContext } from "../../Context/AppContext";

export default function DashboardNav() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const LogoutAdmin = async () => {
    context && context.logoutAdmin();
  };
  return (
    <AppShell id="do-not-print">
      {context && context.profile && (
        <AppShell.Header style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Container maxWidth="xl">
            <div className="flex-row dashboard-nav align-center justify-between">
              <Link to="/" className="home-link">
                <img src={Logo} alt="" />
              </Link>
              <div className="right flex-row align-center">
                <Menu
                  shadow="md"
                  position="bottom-start"
                  width={200}
                  onChange={(e) => {
                    setMenuOpen(e);
                  }}
                >
                  <Menu.Target>
                    <ActionIcon>
                      {isMenuOpen ? (
                        <IconX size={18} />
                      ) : (
                        <IconMenu2 size={18} />
                      )}
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {ProtectedRouteList.map((route) => {
                      return (
                        <NavLink
                          href={`/dashboard/${route.route}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/dashboard/${route.route}`);
                          }}
                          label={route.label}
                          leftSection={<route.icon size="1rem" stroke={1.5} />}
                        />
                      );
                    })}
                  </Menu.Dropdown>
                </Menu>
                &nbsp; &nbsp;
                <Menu>
                  <Menu.Target>
                    <Avatar
                      src={null}
                      alt="no image here"
                      color="indigo"
                      style={{ cursor: "pointer" }}
                    />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>
                      <Text size="xs">
                        {context?.profile.firstname} {context?.profile.lastname}
                      </Text>
                    </Menu.Label>
                    <Link to="/dashboard/account">
                      <Menu.Item color="blue" leftSection={<IconUserCircle />}>
                        My Account
                      </Menu.Item>
                    </Link>
                    <Menu.Divider />
                    <Menu.Item
                      color="red"
                      leftSection={<IconLogout2 />}
                      onClick={LogoutAdmin}
                    >
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>
          </Container>
        </AppShell.Header>
      )}
    </AppShell>
  );
}
