import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "@mantine/form";
import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  rem,
  PinInput,
  PasswordInput,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../Lib/Methods";
import { DefaultResponse } from "../../Lib/Responses";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import Logo from "../../Assets/IMG/Logo.svg";

import "./styles.scss";
import classes from "./ForgotPassword.module.css";

export function ForgotPassword() {
  const navigate = useNavigate();
  const resetForm = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email address",
    },
  });

  const [isRequestingOTP, setRequestingOTP] = useState<boolean>(false);
  const [isChangingPassword, setChangingPassword] = useState<boolean>(false);
  const [isOtpSent, setOtpSent] = useState<boolean>(false);

  const [token, setToken] = useState<string>("");

  const passwordForm = useForm({
    initialValues: {
      otp: "",
      password: "",
    },
    validate: {
      otp: (value) => (value.length !== 6 ? "Please enter a valid OTP" : null),
      password: (value) =>
        value.length < 6 ? "At least SIX characters" : null,
    },
  });

  const RequestOTP = async () => {
    const { hasErrors } = resetForm.validate();
    if (!hasErrors) {
      setRequestingOTP(true);
      const { email } = resetForm.values;
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        data: { email, userType: "admin" },
        route: Endpoints.RequestForgotOTP,
      }).catch(() => {
        DefaultErrorNotification("Could not send OTP!");
        setRequestingOTP(false);
      });
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("OTP sent to your email");
        setToken(r.data.data);
        setOtpSent(true);
      }
    }
  };
  const ChangePasswordWithOTP = async () => {
    const { hasErrors } = passwordForm.validate();
    if (!hasErrors) {
      setChangingPassword(true);
      const { password, otp } = passwordForm.values;
      const r: DefaultResponse = await PerformRequest({
        method: "PATCH",
        data: { password, otp },
        route: `${Endpoints.ResetPasswordWithOTP}=${token}`,
      }).catch(() => {
        DefaultErrorNotification("Could not update password!");
        setChangingPassword(false);
      });
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("Your password has been changed!");
        navigate("/login");
      }
    }
  };
  return (
    <div className="forgot-password-container flex-row align-center justify-center width-100">
      <div className="forgot-form">
        <div className="flex-row width-100 align-center justify-center">
          <img src={Logo} alt="" />
        </div>
        <Title fz="lg" fw={500} ta="center">
          Forgot your password?
        </Title>
        <Text c="dimmed" fz="sm" fw={500} ta="center">
          {isOtpSent
            ? "Enter OTP sent to your mail and new password"
            : "Enter your email to get a reset link"}
        </Text>

        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          {isOtpSent ? (
            <>
              <Text fz="sm" mb={2}>
                Enter OTP sent to your email
              </Text>
              <PinInput
                type={/^[0-9]*$/}
                inputType="tel"
                length={6}
                size="md"
                {...passwordForm.getInputProps("otp")}
                inputMode="numeric"
              />
              <PasswordInput
                mt={15}
                label="New Password"
                placeholder="Enter your new password"
                {...passwordForm.getInputProps("password")}
              />
            </>
          ) : (
            <TextInput
              label="Your email"
              placeholder="Enter your email"
              required
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  RequestOTP();
                }
              }}
              {...resetForm.getInputProps("email")}
            />
          )}
          <Group justify="space-between" mt="lg" className={classes.controls}>
            <Link to="/login">
              <Text c="dimmed">
                <Anchor c="dimmed" size="sm" className={classes.control}>
                  <Center inline>
                    <IconArrowLeft
                      style={{ width: rem(12), height: rem(12) }}
                      stroke={1.5}
                    />
                    <Box ml={5}>Back to the login page</Box>
                  </Center>
                </Anchor>
              </Text>
            </Link>
            {isOtpSent ? (
              <Button
                loading={isChangingPassword}
                className={classes.control}
                onClick={ChangePasswordWithOTP}
              >
                Update password
              </Button>
            ) : (
              <Button
                loading={isRequestingOTP}
                className={classes.control}
                onClick={RequestOTP}
              >
                Reset password
              </Button>
            )}
          </Group>
        </Paper>
      </div>
    </div>
  );
}
