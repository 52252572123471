import {
  IconActivity,
  IconClipboardList,
  IconUserCircle,
  IconUserCog,
  IconUsersGroup,
  IconWheelchair,
} from "@tabler/icons-react";

const ProtectedRouteList = [
  // {
  //   label: "My Account",
  //   icon: IconUserCircle,
  //   route: "account",
  //   elevated: false,
  // },
  { label: "Vendors", icon: IconUserCog, route: "vendors", elevated: false },
  {
    label: "Patients",
    icon: IconWheelchair,
    route: "patients",
    elevated: false,
  },
  {
    label: "Manage Admins",
    icon: IconUsersGroup,
    route: "admin",
    elevated: true,
  },
  {
    label: "Transactions",
    icon: IconClipboardList,
    route: "transactions",
    elevated: false,
  },
  // { label: "Activity", icon: IconActivity, route: "activity", elevated: true },
];

export { ProtectedRouteList };
