import { useEffect, useState } from "react";

import {
  useNavigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { nprogress } from "@mantine/nprogress";

import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import Overview from "../Overview";
import Vendors from "../Vendors";
import SingleVendor from "../SingleVendor";
import AdminComponent from "../Admin";
import Product from "../Product";
import Activity from "../Activity";
import SingleAdmin from "../SingleAdmin";
import Transactions from "../Transactions";
import SingleTransaction from "../SingleTransaction";
import Patients from "../Patients";
import SinglePatient from "../SinglePatient";
import Account from "../Account";
import { Footer } from "../Footer";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Admin, Vendor } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { AppContext, ContextProps } from "../../Context/AppContext";
import Cookies from "js-cookie";
import { DefaultResponse } from "../../Lib/Responses";
import { notifications } from "@mantine/notifications";
import Loader from "../../Misc/Loader";

export default function Dashboard() {
  const navigate = useNavigate();
  const { data: admins, isLoading: isFetchingAdmins } = usePerformRequest<
    Admin[]
  >({
    method: "GET",
    url: Endpoints.GetAllAdmins,
  });
  const {
    data: profile,
    isLoading: isLoadingProfile,
    reloadData: refreshProfile,
  } = usePerformRequest<Admin>({
    method: "GET",
    url: Endpoints.GetProfile,
  });
  const {
    data: vendors,
    isLoading: isLoadingVendors,
    reloadData: reloadVendors,
  } = usePerformRequest<Vendor[]>({
    method: "GET",
    url: `${Endpoints.GetAllVendors}?page=1&limit=20`,
  });

  const VerifyAdminToken = async () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const r: DefaultResponse = await PerformRequest({
        method: "GET",
        route: Endpoints.GetProfile,
      }).catch(() => {});
      const verified = r && r.data && r.data.statusCode === 200;
      if (!verified) {
        notifications.show({
          title: "Authentication Required",
          message: "You need to log in again!",
          color: "orange",
        });
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    //Verify if Admin token makes sense 🤔
    VerifyAdminToken();
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const logoutAdmin = () => {
    Cookies.remove("token");
    navigate("/login");
  };
  return (
    <>
      <AppContext.Provider
        value={{
          profile: profile ?? ({} as unknown as Admin),
          isLoadingProfile,
          reloadProfile: refreshProfile,
          admins: admins ?? [],
          isFetchingAdmins: isFetchingAdmins,
          vendors: vendors ?? [],
          isLoadingVendors,
          reloadVendors,
          logoutAdmin: logoutAdmin,
        }}
      >
        <DashboardNav />
        <br />
        {isLoadingProfile ? (
          <Loader />
        ) : (
          <>
            <Routes>
              <Route index path="/" element={<Overview />} />
              <Route index path="/account" element={<Account />} />
              <Route index path="/admin" element={<AdminComponent />} />
              <Route index path="/admin/:adminID" element={<SingleAdmin />} />
              <Route index path="/activity" element={<Activity />} />
              <Route index path="/product/:productID" element={<Product />} />
              <Route index path="/vendors" element={<Vendors />} />
              <Route
                index
                path="/vendor/:vendorID"
                element={<SingleVendor />}
              />
              <Route index path="/transactions" element={<Transactions />} />
              <Route
                index
                path="/transaction/:transactionID"
                element={<SingleTransaction />}
              />
              <Route index path="/patients" element={<Patients />} />
              <Route
                index
                path="/patient/:patientID"
                element={<SinglePatient />}
              />
            </Routes>
            <Footer />
          </>
        )}
      </AppContext.Provider>
    </>
  );
}
