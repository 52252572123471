import { useState } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import {
  Table,
  Select,
  Button,
  Modal,
  Text,
  SimpleGrid,
  Card,
} from "@mantine/core";
import {
  IconPencil,
  IconCheck,
  IconClock,
  IconWallet,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import "./styles.scss";
import {
  generateRandomString,
  getFinancialValueFromNumeric,
  getFullDate,
} from "../../../Lib/Methods";
const payouts = [
  {
    id: 1,
    name: "Leanne Graham",
    email: "Sincere@april.biz",

    phone: "1-770-736-8031 x56442",
    role: "admin",
  },
  {
    id: 2,
    name: "Ervin Howell",
    email: "Shanna@melissa.tv",

    phone: "010-692-6593 x09125",
    role: "sub-admin",
  },
  {
    id: 3,
    name: "Clementine Bauch",
    email: "Nathan@yesenia.net",

    phone: "1-463-123-4447",
    role: "sub-admin",
  },
  {
    id: 4,
    name: "Patricia Lebsack",
    email: "Julianne.OConner@kory.org",

    phone: "493-170-9623 x156",
    role: "admin",
  },
  {
    id: 5,
    name: "Chelsey Dietrich",
    email: "Lucio_Hettinger@annie.ca",

    phone: "(254)954-1289",
    role: "admin",
  },
  {
    id: 6,
    name: "Mrs. Dennis Schulist",
    email: "Karley_Dach@jasper.info",

    phone: "1-477-935-8478 x6430",
    role: "sub-admin",
  },
  {
    id: 7,
    name: "Kurtis Weissnat",
    email: "Telly.Hoeger@billy.biz",

    phone: "210.067.6132",
    role: "admin",
  },
];
export default function PayoutTab() {
  const [
    isPayoutModalOpened,
    { open: OpenPayoutModal, close: ClosePayoutModal },
  ] = useDisclosure();
  return (
    <Container maxWidth="xl" className="payout-tab">
      <div className="flex-row width-100 justify-between">
        <Text fw={500} size="20px">
          Payouts
        </Text>
        <Button
          color="green"
          rightSection={<IconWallet size={18} />}
          onClick={OpenPayoutModal}
        >
          Make Payout
        </Button>
      </div>

      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Date</Table.Th>
            <Table.Th>Reference</Table.Th>
            <Table.Th>Amount (₦)</Table.Th>
            <Table.Th>Admin</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {payouts.map((payout) => {
            return (
              <Table.Tr key={payout.id}>
                <Table.Td>{getFullDate(Date.now())}</Table.Td>
                <Table.Td className="uppercase">
                  {generateRandomString(12)}
                </Table.Td>
                <Table.Td>{getFinancialValueFromNumeric(34542)}</Table.Td>

                <Table.Td>
                  <div className="flex-row align-center">
                    <Link to={`/dashboard/admin/${payout.id}`}>
                      <Button variant="subtle">{payout.name}</Button>
                    </Link>
                  </div>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      <Modal
        opened={isPayoutModalOpened}
        onClose={ClosePayoutModal}
        title="Confirm Vendor Payout"
        centered
      >
        <Text size="lg">
          Are you sure you want to perform a Payout to Synlab Nigeria?
        </Text>
        <Text size="md" c="blue">
          <div className="flex-row align-center">
            <IconExclamationCircle size={20} />
            &nbsp; This action cannot be reversed!
          </div>
        </Text>
        <br />
        <div className="flex-row align-center justify-between width-100">
          <Button style={{ width: "48%" }} color="green">
            Confirm
          </Button>
          <Button
            style={{ width: "48%" }}
            color="red"
            variant="subtle"
            onClick={ClosePayoutModal}
          >
            Cancel
          </Button>
        </div>
        <br />
      </Modal>
    </Container>
  );
}
