import { useNavigate } from "react-router-dom";

import { Title, Text, Button, Container, Group } from "@mantine/core";
import classes from "./UnauthorizedResource.module.css";

export default function UnauthorizedResource() {
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.label}>500</div>
        <Title className={classes.title}>
          Administrative Privileges Required
        </Title>
        <Text size="lg" ta="center" className={classes.description}>
          You do not have priviledges to perform this function!
        </Text>
        <Group justify="center">
          <Button
            variant="white"
            size="md"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Group>
      </Container>
    </div>
  );
}
