import { useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  Select,
  Table,
  TextInput,
  Alert,
  Text,
  Button,
  AppShell,
  Modal,
  Pagination,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconInfoCircle, IconAt } from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import { getFullDate } from "../../Lib/Methods";
import { PaginationProps, Patient } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { PaginatedResponse } from "../../Lib/Responses";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import Loader from "../../Misc/Loader";

export default function Patients() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const [patientPagination, setPatientPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const patientsFetchURL = useMemo(
    () =>
      `${Endpoints.GetAlPatients}?page=${patientPagination.page}&limit=${patientPagination.limit}`,
    [patientPagination]
  );
  const {
    data: patients,
    response: patientsResponse,
    isLoading: isLoadingPatients,
    reloadData: reloadPatients,
  } = usePerformRequest<Patient[], PaginatedResponse<Patient>>({
    method: "GET",
    url: patientsFetchURL,
  });

  return (
    <AppShell padding="lg">
      <Container maxWidth="xl" className="vendors-container">
        <div className="flex-row align-center justify-between width-100">
          <Text fw={500} size="25px">
            Patients
          </Text>
        </div>
        <br />
        {isLoadingPatients || !patients ? (
          <Loader />
        ) : (
          <>
            {patients.length === 0 ? (
              <div className="flex-row align-center width-100 justify-center">
                <Alert
                  variant="light"
                  color="blue"
                  className="alert"
                  title="No Patients Found"
                  icon={<IconInfoCircle />}
                >
                  There are no patients on this platform
                  <br />
                  <br />
                </Alert>
              </div>
            ) : (
              <>
                <Table verticalSpacing="md" withTableBorder>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Patient Name</Table.Th>
                      <Table.Th>Phone</Table.Th>
                      <Table.Th>Email</Table.Th>

                      <Table.Th>Date Joined</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {patients.map((patient, index) => {
                      return (
                        <Table.Tr key={patient.id}>
                          <Table.Td>
                            {patient.firstname} {patient.lastname}
                          </Table.Td>

                          <Table.Td>{patient.phone}</Table.Td>
                          <Table.Td>{patient.email}</Table.Td>

                          <Table.Td>{getFullDate(patient.createdAt)}</Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
                <div className="flex-row align-center justify-center width-100">
                  <Pagination
                    disabled={isLoadingPatients}
                    className="pagination"
                    my={30}
                    onChange={(e) => {
                      setPatientPagination({ ...patientPagination, page: e });
                    }}
                    total={Math.ceil((patientsResponse?.total ?? 100) / 20)}
                  />
                </div>
              </>
            )}
          </>
        )}
        <br />
      </Container>
    </AppShell>
  );
}
