import { useState, useContext } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import {
  Button,
  Modal,
  Select,
  Alert,
  Table,
  Text,
  TextInput,
  NumberInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  IconAt,
  IconUserCircle,
  IconInfoCircle,
  IconPhone,
} from "@tabler/icons-react";

import { AppContext } from "../../Context/AppContext";
import "./styles.scss";
import {
  DefaultErrorNotification,
  validateEmail,
  validatePhone,
} from "../../Lib/Methods";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { DefaultResponse } from "../../Lib/Responses";
import Loader from "../../Misc/Loader";
import { Role } from "../../Lib/Types";
import { AxiosError } from "axios";

export default function Admin() {
  const [opened, { open: OpenAdminModal, close: CloseAdminModal }] =
    useDisclosure(false);

  const context = useContext(AppContext);

  interface AdminFormProps {
    firstname: string;
    lastname: string;
    email: string;
    role: Role;
    phone: string;
  }

  const createAdminForm = useForm<AdminFormProps>({
    initialValues: {
      email: "",
      role: "SUBADMIN",
      firstname: "",
      lastname: "",
      phone: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
      role: (value) => (value.length === 0 ? "Specify admin role" : null),
      firstname: (value) =>
        value.length === 0 ? "Specify admin firstname" : null,
      lastname: (value) =>
        value.length === 0 ? "Specify admin lastname" : null,
      phone: (value) =>
        !validatePhone(value.toString()) ? "Enter a valid phone!" : null,
    },
  });

  const [isCreatingAdmin, setCreatingAdmin] = useState<boolean>(false);
  const CreateNewAdmin = async () => {
    if (context?.profile.role === "ADMIN") {
      const { email, role, firstname, lastname } = createAdminForm.values;
      setCreatingAdmin(true);
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        data: { email, role, firstname, lastname },
        route: Endpoints.AddNewAdmin,
      }).catch((e: AxiosError) => {
        setCreatingAdmin(false);
        if (e.response?.status === 500) {
          DefaultErrorNotification("Email already exists!");
        }
      });
      setCreatingAdmin(false);

      if (
        r &&
        r.data &&
        (r.data.statusCode === 200 || r.data.statusCode === 201)
      ) {
        notifications.show({
          color: "green",
          message: "Admin successfully onboarded!",
        });
        CloseAdminModal();
      }
    } else {
      DefaultErrorNotification(
        "You are not authorized to perform this action!"
      );
    }
  };
  return (
    <>
      {context && (
        <Container maxWidth="xl" className="admin-container">
          <div className="flex-row width-100 justify-between align-center">
            <Text fw={500} size="25px">
              Admins
            </Text>
            {context.profile.role === "ADMIN" && (
              <Button onClick={OpenAdminModal}>Add New Profile</Button>
            )}
          </div>
          <br />
          {context.isFetchingAdmins ? (
            <Loader />
          ) : (
            <>
              {context.admins.length === 0 ? (
                <div className="flex-row align-center width-100 justify-center">
                  <Alert
                    variant="light"
                    color="blue"
                    className="alert"
                    title="No Admins Found"
                    icon={<IconInfoCircle />}
                  >
                    There are no admins on this platform. You need to register
                    admins using their&nbsp;
                    <b>email address</b>
                    <br />
                    <br />
                    <Button onClick={OpenAdminModal}>
                      Create Admin Profile
                    </Button>
                  </Alert>
                </div>
              ) : (
                <Table verticalSpacing="xs">
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Email</Table.Th>
                      <Table.Th>Phone Number</Table.Th>
                      <Table.Th>Role</Table.Th>
                      <Table.Th>Actions</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {context.admins.map((admin) => {
                      return (
                        <Table.Tr key={admin.id}>
                          <Table.Td className="capitalize">
                            {admin.firstname} {admin.lastname}
                          </Table.Td>
                          <Table.Td>{admin.email}</Table.Td>
                          <Table.Td>{admin.phone}</Table.Td>
                          <Table.Td>
                            {admin.role === "ADMIN" ? "Admin" : "Sub Admin"}
                          </Table.Td>
                          <Table.Td>
                            <div className="flex-row align-center">
                              <Link to={`/dashboard/admin/${admin.id}`}>
                                <Button variant="subtle">View Details</Button>
                              </Link>
                            </div>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
              )}
            </>
          )}
          <Modal
            opened={opened}
            onClose={CloseAdminModal}
            title="Create New Admin"
            centered
          >
            <form
              className="admin-modal flex-col"
              onSubmit={createAdminForm.onSubmit(
                (values, _event) => {
                  CreateNewAdmin();
                },
                (validationErrors, _values, _event) => {}
              )}
            >
              <div className="flex-row align-center justify-between width-100">
                <TextInput
                  disabled={isCreatingAdmin}
                  placeholder="Enter First Name"
                  label="First Name"
                  {...createAdminForm.getInputProps("firstname")}
                />
                &nbsp; &nbsp;
                <TextInput
                  disabled={isCreatingAdmin}
                  placeholder="Enter Last Name"
                  {...createAdminForm.getInputProps("lastname")}
                  label="Last Name"
                />
              </div>

              <TextInput
                disabled={isCreatingAdmin}
                label="Email Address"
                placeholder="Enter email address"
                {...createAdminForm.getInputProps("email")}
                leftSection={<IconAt size={16} />}
              />
              <div className="flex-row align-center justify-between width-100">
                <Select
                  label="Role"
                  disabled={isCreatingAdmin}
                  placeholder="Select Role - Admin, Sub Admin"
                  data={[
                    { label: "Admin", value: "ADMIN" },
                    { label: "Sub Admin", value: "SUBADMIN" },
                  ]}
                  {...createAdminForm.getInputProps("role")}
                />
                &nbsp; &nbsp;
                <NumberInput
                  disabled={isCreatingAdmin}
                  placeholder="Input phone number"
                  {...createAdminForm.getInputProps("phone")}
                  label="Phone"
                  hideControls
                  min={0}
                  prefix="+234 "
                  leftSection={<IconPhone size={14} />}
                />
              </div>
              <Button
                loading={isCreatingAdmin}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  if (createAdminForm.validate().hasErrors) {
                    notifications.show({
                      title: "Error",
                      color: "red",
                      message: "Please fill the form correctly! 🤥",
                    });
                  } else {
                    CreateNewAdmin();
                  }
                }}
              >
                Create Profile
              </Button>
            </form>
          </Modal>
        </Container>
      )}
    </>
  );
}
