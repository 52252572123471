import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { baseURL as defaultBaseURL, Endpoints } from "./Endpoints";
import { DefaultResponse } from "./Responses";
interface PerformRequestOptions {
  method: "POST" | "GET" | "PUT" | "DEL" | "PATCH";
  data?: any;
  route: string;
}

const PerformRequest = async ({
  method,
  data,
  route,
}: PerformRequestOptions) => {
  const token = Cookies.get("token");
  const config = {
    method: method === "DEL" ? "DELETE" : method,
    data,
    url: route,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const r: any = axios.request(config);
  return r;
};

const UploadFile = async (files: File[]) => {
  const token = Cookies.get("token");

  const fileFormData = new FormData();
  files.map((f, index) => {
    fileFormData.append(
      `file-${index}`,
      f,
      f.name.toLowerCase().split(" ").join().replaceAll(",", "")
    );
  });
  fileFormData.append("token", `Bearer ${token}` ?? "");
  const config: AxiosRequestConfig = {
    method: "POST",
    url: Endpoints.UploadFile,

    data: fileFormData,
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${token}`,

      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios.request(config);

  return response as any;
};

interface RequestOptions {
  method: "POST" | "GET" | "PUT";
  url: string;
  body?: any;
}
function usePerformRequest<Type, ResponseType = DefaultResponse>({
  method,
  url,
  body,
}: RequestOptions) {
  const token = Cookies.get("token");
  const [data, setData] = useState<Type>();
  const [response, setResponse] = useState<ResponseType>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const reloadData = async () => {
    const config: AxiosRequestConfig = {
      method,
      data: body,
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    const r = await axios(config);
    setLoading(false);
    if (r.data) {
      setResponse(r.data);
      setData(
        r.data.statusCode === 200 ? r.data.payload ?? r.data.data : undefined
      );
    }
  };
  useEffect(() => {
    reloadData().catch(() => setLoading(false));
  }, [url]);

  return { response, data, isLoading, reloadData };
}

export { usePerformRequest, PerformRequest, UploadFile };
