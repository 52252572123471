const baseURL = "https://quickhealth-production-server.up.railway.app";
const authURL = `${baseURL}/auth/admin"`;
const vendorURL = `${baseURL}/vendor"`;
const Endpoints = {
  LoginWithPassword: `${baseURL}/auth/signin/password`,
  Login: `${authURL}/login`,
  ValidateOTP: `${authURL}/otp`,
  GetProfile: `${baseURL}/admin/account`,
  RequestForgotOTP: `${baseURL}/auth/forgot`,
  ResetPasswordWithOTP: `${baseURL}/auth/verify/otp?token`,

  GetAllAdmins: `${baseURL}/admin/all`,
  GetSingleAdmin: `${baseURL}/admin/profile/user`,
  AddNewAdmin: `${baseURL}/admin/onboard`,
  UpdateSingleAdminProfile: `${baseURL}/admin/user/update`,
  UpdateProfile: `${baseURL}/admin/profile/update`,
  ToggleAdminActivation: `${baseURL}/admin/profile/activation`,
  DeleteAdmin: `${baseURL}/admin/profile/delete`,

  GetAlPatients: `${baseURL}/patient/all`,

  CreateProduct: `${baseURL}/product/admin/create`,
  UpdateProduct: `${baseURL}/product/admin/edit`,
  GetVendorProducts: `${baseURL}/product/all`,
  DeleteProduct: `${baseURL}/product/admin/delete`,
  PublishProduct: `${baseURL}/product/admin/publish`,
  GetSingleProduct: `${baseURL}/product/find`,

  OnboardVendor: `${vendorURL}/onboard`,
  GetAllVendors: `${baseURL}/admin/vendor/all`,
  GetSingleVendor: `${baseURL}/admin/vendor/account?id`,
  ToggleVendorActivation: `${baseURL}/vendor/activation`,

  UpdateVendorProfile: `${baseURL}/admin/vendor/update?id`,
  AddVendorBankDetails: `${baseURL}/admin/bank/add`,
  UpdateVendorBankDetails: `${baseURL}/admin/bank/update`,

  CreateNewOutlet: `${baseURL}/outlet/admin/onboard`,
  GetVendorOutlets: `${baseURL}/outlet/admin/all`,
  DeleteVendorOutlet: `${baseURL}/outlet/delete`,
  UpdateVendorOutlet: `${baseURL}/outlet/admin/update`,

  GetAllTransactions: `${baseURL}/transaction/all`,
  GetSingleTransaction: `${baseURL}/transaction`,

  UploadFile: `${baseURL}/picture/upload`,
};

export { Endpoints, baseURL, authURL, vendorURL };
