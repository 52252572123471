import React, { useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import {
  Text,
  Table,
  Badge,
  Button,
  CopyButton,
  Alert,
  Pagination,
} from "@mantine/core";

import { getFinancialValueFromNumeric, getFullDate } from "../../Lib/Methods";

import "./styles.scss";
import { PaginationProps, Transaction } from "../../Lib/Types";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { PaginatedResponse } from "../../Lib/Responses";
import { Endpoints } from "../../Lib/Endpoints";
import Loader from "../../Misc/Loader";
type TransactionPageProps = {
  recent?: boolean;
};
export default function Transactions({ recent = false }: TransactionPageProps) {
  const [transactionPagination, setTransactionPagination] =
    useState<PaginationProps>({
      page: 1,
      limit: recent ? 5 : 20,
    });
  const {
    data: transactions,
    response: transactionsResponse,
    isLoading: isLoadingTransactions,
    reloadData: reloadTransactions,
  } = usePerformRequest<Transaction[], PaginatedResponse<Transaction>>({
    method: "GET",

    url: `${Endpoints.GetAllTransactions}?page=${transactionPagination.page}&limit=${transactionPagination.limit}`,
  });

  return (
    <Container className={recent ? "" : "transactions-container"} maxWidth="xl">
      {!recent && (
        <>
          <div className="flex-row align-center justify-between width-100">
            <Text fw={500} size="25px">
              Transactions
            </Text>
            <Button
              loading={isLoadingTransactions}
              onClick={reloadTransactions}
            >
              Refresh
            </Button>
          </div>
          <br />
        </>
      )}
      {isLoadingTransactions ? (
        <Loader />
      ) : (
        <>
          {transactions && transactions.length !== 0 ? (
            <>
              <Table verticalSpacing="md" withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Date</Table.Th>
                    <Table.Th>Reference</Table.Th>
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>Vendor</Table.Th>
                    <Table.Th>Details</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {transactions.map((transaction, index) => {
                    return (
                      <Table.Tr>
                        <Table.Td>
                          <Text>
                            {getFullDate(parseInt(transaction.timestamp))}
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <CopyButton value={transaction?.reference}>
                            {({ copied, copy }) => (
                              <Text
                                className="uppercase reference"
                                onClick={copy}
                                c={copied ? "green" : "#1971c2"}
                                fw={500}
                              >
                                {transaction?.reference}
                              </Text>
                            )}
                          </CopyButton>
                        </Table.Td>
                        <Table.Td>
                          <Text className="uppercase" fw={500} fz="18px">
                            ₦{getFinancialValueFromNumeric(transaction?.amount)}
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <Badge className="uppercase" fw={500} color="green">
                            Success
                          </Badge>
                        </Table.Td>
                        <Table.Td>
                          <Text tt="capitalize">
                            {transaction?.offerings[0]?.provider?.name}
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <Link to={`/dashboard/transaction/${transaction.id}`}>
                            <Button variant="subtle">View Details</Button>
                          </Link>
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
              {!recent && (
                <div className="flex-row align-center justify-center width-100">
                  <Pagination
                    className="pagination"
                    my={30}
                    onChange={(e) => {
                      setTransactionPagination({
                        ...transactionPagination,
                        page: e,
                      });
                    }}
                    total={Math.ceil((transactionsResponse?.total ?? 100) / 20)}
                  />
                </div>
              )}
            </>
          ) : (
            <Container maxWidth="sm">
              <Alert title="No results">
                <Text fz="md" fw={500}>
                  No transactions!
                </Text>
                <div className="flex-row align-center">
                  <Text c="dimmed">No transactions found</Text>
                  &nbsp; &nbsp;
                </div>
              </Alert>
            </Container>
          )}
        </>
      )}
    </Container>
  );
}
