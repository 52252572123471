import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { NavigationProgress, nprogress } from "@mantine/nprogress";
import { Notifications } from "@mantine/notifications";

import "./index.scss";
import "@mantine/core/styles.css";
import "@mantine/nprogress/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/carousel/styles.css";

import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Test from "./Pages/Test";
import Overview from "./Pages/Overview";
import Vendors from "./Pages/Vendors";
import SingleVendor from "./Pages/SingleVendor";
import Admin from "./Pages/Admin";
import Product from "./Pages/Product";
import Activity from "./Pages/Activity";
import SingleAdmin from "./Pages/SingleAdmin";
import Transactions from "./Pages/Transactions";
import SingleTransaction from "./Pages/SingleTransaction";
import Patients from "./Pages/Patients";
import SinglePatient from "./Pages/SinglePatient";
import Account from "./Pages/Account";
import { ForgotPassword } from "./Pages/ForgotPassword";

function RedirectToDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
    navigate("/dashboard");
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  return <span></span>;
}
function App() {
  return (
    <MantineProvider
      theme={{
        fontFamily: "Outfit, sans-serif",
      }}
    >
      <NavigationProgress />
      <Notifications position="top-right" />

      {/* <Test /> */}
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" index element={<RedirectToDashboard />} />
          <Route path="/reset-password" element={<ForgotPassword />} />

          <Route path="/dashboard" element={<Dashboard />}>
            <Route index path="/dashboard" element={<Overview />} />
            <Route index path="/dashboard/activity" element={<Activity />} />
            <Route index path="/dashboard/admin" element={<Admin />} />
            <Route
              index
              path="/dashboard/admin/:adminID"
              element={<SingleAdmin />}
            />
            <Route
              index
              path="/dashboard/product/:productID"
              element={<Product />}
            />
            <Route index path="/dashboard/vendors" element={<Vendors />} />
            <Route
              index
              path="/dashboard/vendor/:vendorID"
              element={<SingleVendor />}
            />
            <Route
              index
              path="/dashboard/transactions"
              element={<Transactions />}
            />
            <Route
              index
              path="/dashboard/transaction/:transactionID"
              element={<SingleTransaction />}
            />
            <Route index path="/dashboard/patients" element={<Patients />} />
            <Route
              index
              path="/dashboard/patient/:patientID"
              element={<SinglePatient />}
            />
            <Route index path="/dashboard/account" element={<Account />} />
          </Route>
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
