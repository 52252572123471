import { useEffect, useState, useContext } from "react";

import { Link, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Select, TextInput, Text, Button, NumberInput } from "@mantine/core";

import { useForm } from "@mantine/form";

import { IconClock, IconPhone, IconUserShield } from "@tabler/icons-react";

import "./styles.scss";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getFullDate,
  validateEmail,
} from "../../Lib/Methods";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { DefaultResponse } from "../../Lib/Responses";
import { Admin, Role } from "../../Lib/Types";
import Loader from "../../Misc/Loader";
import { AppContext } from "../../Context/AppContext";
import UnauthorizedResource from "../UnauthorizedResource";
import { AxiosError } from "axios";

export default function SingleAdmin() {
  const context = useContext(AppContext);
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  interface AdminFormProps {
    id?: string;
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    role: Role;
  }
  const editAdminForm = useForm<AdminFormProps>({
    initialValues: {
      id: "",
      email: "",
      phone: "",
      firstname: "",
      lastname: "",
      role: "ADMIN",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
      role: (value) => (value.length === 0 ? "Specify admin role" : null),
      phone: (value) =>
        !value || value.toString().length < 9
          ? "Enter a valid phone number"
          : null,
      firstname: (value) =>
        value.length === 0 ? "Specify admin firstname" : null,
      lastname: (value) =>
        value.length === 0 ? "Specify admin lastname" : null,
    },
  });

  const [isUpdatingAdmin, setUpdatingAdmin] = useState<boolean>(false);
  const UpdateSingleAdminProfile = async () => {
    if (context?.profile.role === "ADMIN") {
      setUpdatingAdmin(true);
      const { id, email, firstname, lastname, role, phone } =
        editAdminForm.values;

      const r: DefaultResponse = await PerformRequest({
        method: "PUT",
        route: `${Endpoints.UpdateSingleAdminProfile}?id=${id}`,
        data: {
          id,
          email,
          firstname,
          lastname,
          role,
          active: admin?.active,
          phone: phone.toString(),
        },
      }).catch(() => {
        DefaultErrorNotification("Could not update profile!");
        setUpdatingAdmin(false);
      });
      setUpdatingAdmin(false);
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("Admin profile updated!");
      }
    }
  };
  const params = useParams();
  const adminID = params.adminID;
  const {
    data: admin,
    isLoading: isLoadingAdminProfile,
    reloadData: refreshAdminProfile,
  } = usePerformRequest<Admin>({
    method: "POST",
    body: { id: adminID },
    url: Endpoints.GetSingleAdmin,
  });

  const [parentAdmin, setParentAdmin] = useState<Admin | null>(null);
  const FetchParentAdmin = async () => {
    if (admin) {
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        data: { id: admin.createdBy ?? "" },
        route: Endpoints.GetSingleAdmin,
      }).catch((e: AxiosError) => {});
      if (r && r.data && r.data.statusCode === 200) {
        setParentAdmin(r.data.data as unknown as Admin);
      }
    }
  };
  useEffect(() => {
    if (admin) {
      editAdminForm.setValues({
        id: admin.id,
        email: admin.email,
        firstname: admin.firstname,
        lastname: admin.lastname,
        role: admin.role,
        phone: admin.phone,
      });
      FetchParentAdmin();
    }
  }, [admin]);
  const [isAdminActivationToggling, setAdminActivationToggling] =
    useState<boolean>(false);
  const [isAdminDeleting, setAdminDeleting] = useState<boolean>(false);

  const ToggleAdminActivation = async () => {
    setAdminActivationToggling(true);
    const r: DefaultResponse = await PerformRequest({
      method: "PUT",
      route: Endpoints.ToggleAdminActivation,
      data: { email: admin?.email, active: !admin?.active },
    }).catch(() => {
      setAdminActivationToggling(false);
      DefaultErrorNotification();
    });
    setAdminActivationToggling(false);

    if (r && r.data && r.data.statusCode === 200) {
      refreshAdminProfile();
      DefaultSuccessNotification(r.data.msg);
    }
  };
  const DeleteAdmin = async () => {
    setAdminDeleting(true);
    const r: DefaultResponse = await PerformRequest({
      method: "DEL",
      route: Endpoints.DeleteAdmin,
      data: { email: admin?.email },
    }).catch(() => {
      setAdminDeleting(false);
      DefaultErrorNotification();
    });
    setAdminDeleting(false);

    if (r && r.data && r.data.statusCode === 200) {
      DefaultSuccessNotification("Deleted Admin Profile!");
    }
  };

  const gridStatsProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  };
  return (
    <>
      {context && context.profile && context.profile.role ? (
        <>
          {context?.profile.role === "ADMIN" ? (
            <>
              {isLoadingAdminProfile ? (
                <Loader />
              ) : (
                <Container maxWidth="lg" className="single-admin-container">
                  <div className="flex-row justify-between width-100">
                    <div className="flex-col justify-between width-100">
                      <Text fw={500} size="20px" mb={5}>
                        Admin Details
                      </Text>
                      <div className="flex-row align-center">
                        <IconClock color="grey" size={20} />
                        <Text c="dimmed">&nbsp; Created On &nbsp;</Text>
                        <Text fw={500}>
                          {getFullDate(admin?.createdAt ?? "")}
                        </Text>
                      </div>
                      <div className="flex-row align-center">
                        <IconUserShield color="grey" size={20} />
                        <Text c="dimmed" mb="5px">
                          &nbsp; Created By &nbsp;
                        </Text>

                        <Text c="blue" tt="capitalize" fw={500} mb="5px">
                          {admin?.createdBy === "FOUNDER" ? (
                            `${admin.firstname} ${admin.lastname}`
                          ) : (
                            <>
                              {parentAdmin?.firstname}&nbsp;
                              {parentAdmin?.lastname}
                            </>
                          )}
                        </Text>
                      </div>
                      {context.profile.role === "ADMIN" && (
                        <Button
                          color="red"
                          onClick={DeleteAdmin}
                          loading={isAdminDeleting}
                          style={{
                            width: "200px",
                          }}
                        >
                          Delete Admin
                        </Button>
                      )}
                    </div>
                    {context.profile.role === "ADMIN" && (
                      <Button
                        style={{ width: "200px" }}
                        color={admin?.active ? "red" : "green"}
                        loading={isAdminActivationToggling}
                        onClick={ToggleAdminActivation}
                      >
                        {admin?.active ? "Deactivate Admin" : "Activate Admin"}
                      </Button>
                    )}
                  </div>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item {...gridStatsProps}>
                      <TextInput
                        disabled={context.profile.role !== "ADMIN"}
                        placeholder="First Name"
                        label="First Name"
                        {...editAdminForm.getInputProps("firstname")}
                      />
                    </Grid>
                    <Grid item {...gridStatsProps}>
                      <TextInput
                        disabled={context.profile.role !== "ADMIN"}
                        placeholder="Last Name"
                        label="Last Name"
                        {...editAdminForm.getInputProps("lastname")}
                      />
                    </Grid>
                    <Grid item {...gridStatsProps}>
                      <TextInput
                        disabled={context.profile.role !== "ADMIN"}
                        placeholder="Email Address"
                        label="Email Address"
                        {...editAdminForm.getInputProps("email")}
                      />
                    </Grid>
                    <Grid item {...gridStatsProps}>
                      <NumberInput
                        label="Phone number"
                        placeholder="Enter company phone"
                        clampBehavior="strict"
                        min={0}
                        leftSection={<IconPhone size={16} />}
                        prefix="+(234) "
                        hideControls
                        {...editAdminForm.getInputProps("phone")}
                      />
                    </Grid>
                    <Grid item xs={12} xl={12}>
                      <Select
                        placeholder="Select Role"
                        label="Select Role"
                        data={[
                          { label: "Admin", value: "ADMIN" },
                          { label: "Sub Admin", value: "SUBADMIN" },
                        ]}
                        {...editAdminForm.getInputProps("role")}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Button
                    fullWidth
                    loading={isUpdatingAdmin}
                    onClick={() => {
                      if (editAdminForm.validate().hasErrors) {
                        DefaultErrorNotification(
                          "Please provide all admin information!"
                        );
                      } else {
                        UpdateSingleAdminProfile();
                      }
                    }}
                  >
                    Save Profile
                  </Button>
                  <br />
                </Container>
              )}
            </>
          ) : (
            <UnauthorizedResource />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
