import { useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  SimpleGrid,
  Alert,
  rem,
  Tabs,
  Button,
  Text,
  Badge,
  AppShell,
  TextInput,
  Select,
  Switch,
  Table,
  ScrollArea,
  Popover,
  Modal,
  Group,
  useMantineTheme,
  Textarea,
  NumberInput,
  TagsInput,
  Image,
  ActionIcon,
  HoverCard,
  Pagination,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from "@mantine/dropzone";

import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import {
  IconInfoCircle,
  IconPencil,
  IconSquareRoundedCheck,
  IconUserCancel,
  IconSearch,
  IconCircleCheck,
  IconCircleX,
  IconX,
  IconTrash,
  IconCurrencyNaira,
  IconUpload,
  IconPhoto,
} from "@tabler/icons-react";
import Logo from "../../Assets/IMG/Logo.svg";
import {
  PerformRequest,
  UploadFile,
  usePerformRequest,
} from "../../../Lib/usePerformRequest";
import { DefaultResponse, PaginatedResponse } from "../../../Lib/Responses";
import { Endpoints } from "../../../Lib/Endpoints";
import { PaginationProps, Service, Vendor } from "../../../Lib/Types";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
} from "../../../Lib/Methods";
import Loader from "../../../Misc/Loader";

interface ProductsTabProps {
  vendor: Vendor;
  reloadVendor: () => Promise<void>;
}

export default function ProductsTab({
  vendor,
  reloadVendor,
}: ProductsTabProps) {
  const theme = useMantineTheme();

  const [formCase, setFormCase] = useState<"create" | "edit">("create");
  const productForm = useForm({
    initialValues: {
      id: "",
      name: "",
      price: 0,
      description: "",
      manufacturer: "",
      stock: 0,
      images: [],
      tags: [] as string[],
    },
    validate: {
      name: (value) => (value.length < 5 ? "Enter product name" : null),
      price: (value) => (value < 0 ? "Enter a valid price " : null),
      description: (value) =>
        value?.length < 20
          ? "Product description must be at least 20 characters"
          : null,
      stock: (value) => (value < 0 ? "Enter number of product in stock" : null),
      tags: (value) =>
        value.length === 0
          ? "Please enter at least ONE tag to enhance product search"
          : null,
    },
  });
  const [
    isCreateProductModalOpen,
    { open: OpenProductModal, close: CloseProductModal },
  ] = useDisclosure(false);

  const [vendorPagination, setVendorPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const [productLoading, setProductLoading] = useState<string>("");

  const ToggleProductPublishing = async (
    status: boolean,
    productID: string
  ) => {
    setProductLoading(productID);
    const r: DefaultResponse = await PerformRequest({
      method: "PATCH",
      route: `${Endpoints.PublishProduct}/${productID}`,
      data: { publish: status },
    }).catch(() => {
      setProductLoading("");
      DefaultErrorNotification("An error occurred!");
    });

    setProductLoading("");

    if (r.data && r.data.statusCode === 200) {
      DefaultSuccessNotification(r.data.msg);
    }
  };

  const productSearchURL = useMemo(
    () =>
      `${Endpoints.GetVendorProducts}/${vendor?.id}?page=${vendorPagination.page}&limit=${vendorPagination.limit}`,
    [vendorPagination]
  );
  const {
    data: products,
    response: productsResponse,
    isLoading: isLoadingProducts,
    reloadData: reloadProducts,
  } = usePerformRequest<Service[], PaginatedResponse<Service>>({
    method: "GET",
    url: productSearchURL,
  });
  useEffect(() => {
    reloadProducts();
  }, [vendorPagination]);
  const ActiveProductIcon = (
    <IconCircleCheck
      style={{ width: rem(16), height: rem(16) }}
      stroke={2.5}
      color="white"
    />
  );

  const InactiveProductIcon = (
    <IconX
      style={{ width: rem(16), height: rem(16) }}
      stroke={2.5}
      color={theme.colors.red[6]}
    />
  );

  const [newProductImages, setNewProductImages] = useState<FileWithPath[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const UploadAllImages = async () => {
    if (newProductImages.length === 0) {
      return [vendor.logo];
    } else {
      const r: any = await UploadFile(newProductImages);
      return r?.data?.data as string[];
    }
  };

  const CreateNewProduct = async () => {
    const { hasErrors } = productForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { name, price, description, manufacturer, stock, tags } =
        productForm.values;
      const images = await UploadAllImages();

      const r: DefaultResponse = await PerformRequest({
        route: `${Endpoints.CreateProduct}/${vendor.id}`,
        method: "POST",
        data: {
          name,
          image: images ? images[0] : "",
          department: vendor.department,
          vendorName: vendor.vendorname,
          manufacturer,
          description,
          price,
          stock,
          tags,

          images,
        },
      }).catch(() => {
        setLoading(false);
        DefaultErrorNotification("Could not create product. Please try again");
      });
      setLoading(false);
      if (r && r.data && r.data.statusCode === 201) {
        DefaultSuccessNotification("Product has been created!");
        reloadProducts();
        CloseProductModal();
      }
    }
  };
  const UpdateProduct = async () => {
    const { hasErrors } = productForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { name, price, description, manufacturer, stock, tags } =
        productForm.values;
      const images = await UploadAllImages();

      const r: DefaultResponse = await PerformRequest({
        route: `${Endpoints.UpdateProduct}/${vendor.id}/${productForm.values.id}`,
        method: "PUT",
        data: {
          name,
          image: images ? images[0] : "",
          department: vendor.department,
          vendorName: vendor.vendorname,
          manufacturer,
          description,
          price,
          stock,
          tags,

          images: [...images, ...productForm.values.images],
        },
      }).catch(() => {
        setLoading(false);
        DefaultErrorNotification("Could not update product. Please try again");
      });
      setLoading(false);
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("Product has been successfully updated!");
        reloadProducts();
        CloseProductModal();
      }
    }
  };
  const [productDeleting, setProductDeleting] = useState<string>("");
  const DeleteProduct = async (productID: string) => {
    setProductDeleting(productID);
    setLoading(true);

    const r: DefaultResponse = await PerformRequest({
      route: `${Endpoints.DeleteProduct}/${productID}`,
      method: "DEL",
      data: {
        productID,
      },
    }).catch(() => {
      setLoading(false);
      setProductDeleting("");
      DefaultErrorNotification("Could not delete product. Please try again");
    });
    setProductDeleting("");
    setLoading(false);
    if (r && r.data && r.data.statusCode === 200) {
      DefaultSuccessNotification("Product has been removed!");
      reloadProducts();
    }
  };
  const searchForm = useForm({
    initialValues: {
      text: "",
    },
    validate: {
      text: (value) => (value.length === 0 ? "Please enter a value" : null),
    },
  });

  const FilterProducts = async () => {
    const { hasErrors } = searchForm.validate();
    if (!hasErrors) {
      setVendorPagination({
        page: 1,
        limit: 20,
      });
      // setProductSearchURL(
      //   `${Endpoints.GetVendorProducts}/${vendor?.id}?page=${vendorPagination.page}&limit=${vendorPagination.limit}`
      // );
      reloadProducts();
    }
  };
  return (
    <>
      {products && (
        <ScrollArea className="products-tab">
          <div className="flex-row search-container">
            <TextInput
              placeholder="Search products"
              className="input"
              mb="md"
              {...searchForm.getInputProps("text")}
              leftSection={
                <IconSearch
                  style={{ width: rem(16), height: rem(16) }}
                  stroke={1.5}
                />
              }
            />
            &nbsp; &nbsp;
            <Button className="btn" onClick={FilterProducts}>
              <IconSearch
                style={{ width: rem(16), height: rem(16) }}
                stroke={3}
              />
            </Button>
          </div>
          <div className="flex-row align-center">
            <Button
              className="show-create-product-btn"
              onClick={() => {
                if (vendor.logo && vendor.logo.length !== 0) {
                  OpenProductModal();
                  setFormCase("create");
                  setNewProductImages([]);
                  productForm.reset();
                } else {
                  DefaultErrorNotification("Upload vendor logo first!");
                }
              }}
            >
              Create New Product
            </Button>
            &nbsp; &nbsp;
            <Button
              variant="subtle"
              onClick={() => {
                reloadProducts();
              }}
            >
              Refresh
            </Button>
          </div>
          {isLoadingProducts ? (
            <Loader />
          ) : (
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              miw={700}
              layout="fixed"
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>Manufacturer</Table.Th>
                  <Table.Th>Image</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Action</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {products.map((product, index) => {
                  return (
                    <Table.Tr key={index} className="record">
                      <Table.Td>{product.name}</Table.Td>
                      <Table.Td>{product.manufacturer}</Table.Td>
                      <Table.Td>
                        <img src={product.image} className="image" alt="" />
                      </Table.Td>
                      <Table.Td>
                        <div className="flex-row align-center">
                          <Switch
                            size="md"
                            disabled={productLoading === product.id}
                            defaultChecked={product.published}
                            onChange={(e) =>
                              ToggleProductPublishing(
                                e.currentTarget.checked,
                                product.id
                              )
                            }
                            color="green.7"
                            onLabel={ActiveProductIcon}
                            offLabel={InactiveProductIcon}
                          />
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <div className="flex-row align-center">
                          {/* <Link
                            to={`/dashboard/product/${"some-product-slug-string"}`}
                          > */}
                          <Button
                            onClick={() => {
                              setFormCase("edit");
                              OpenProductModal();
                              setNewProductImages([]);
                              productForm.reset();

                              productForm.setValues({
                                id: product.id ?? "",
                                name: product.name ?? "",
                                price: product.price ?? 0,
                                description: product.description ?? "",
                                manufacturer: product.manufacturer ?? "",
                                stock: product.stock ?? 0,
                                images: (product.images as never[]) ?? [],
                                tags: product.tags ?? [],
                              });
                            }}
                            variant="subtle"
                          >
                            Edit
                          </Button>
                          {/* </Link> */}
                          &nbsp; &nbsp;
                          <Popover
                            width={330}
                            position="bottom"
                            withArrow
                            shadow="md"
                          >
                            <Popover.Target>
                              <Button
                                color="red"
                                variant="subtle"
                                loading={productDeleting === product.id}
                              >
                                <IconTrash size="20px" />
                              </Button>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Text fw={500} size="12.5px">
                                Are you sure you want to delete this product?
                              </Text>
                              <Button
                                style={{ marginTop: "10px" }}
                                color="red"
                                onClick={() => {
                                  DeleteProduct(product.id);
                                }}
                                loading={productDeleting === product.id}
                              >
                                Delete
                              </Button>
                            </Popover.Dropdown>
                          </Popover>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
          )}
          <Pagination
            disabled={isLoadingProducts}
            className="pagination"
            my={30}
            onChange={(e) => {
              setVendorPagination({ ...vendorPagination, page: e });
            }}
            total={Math.ceil((productsResponse?.total ?? 100) / 20)}
          />
          <Modal
            opened={isCreateProductModalOpen}
            onClose={CloseProductModal}
            title={`${formCase === "create" ? "Add" : "Edit"} Product`}
            centered
            size="xl"
            className="new-product-modal"
          >
            <form className="flex-col new-product-form">
              <SimpleGrid
                cols={{ base: 1, xs: 1, sm: 1, md: 2, lg: 2 }}
                spacing={{ base: 20, xs: 20, sm: 20, md: 20, lg: 20 }}
                verticalSpacing={{ base: 5 }}
              >
                <TextInput
                  label="Product Name"
                  placeholder="Enter product name"
                  required
                  {...productForm.getInputProps("name")}
                />
                <NumberInput
                  hideControls
                  thousandSeparator=","
                  label="Product Price"
                  required
                  leftSection={<IconCurrencyNaira size={17} />}
                  {...productForm.getInputProps("price")}
                />
              </SimpleGrid>
              <Textarea
                label="Product Description"
                placeholder="Enter a short description of your product for customers to understand better"
                required
                {...productForm.getInputProps("description")}
              />
              <SimpleGrid
                cols={{ base: 1, xs: 1, sm: 1, md: 2, lg: 2 }}
                spacing={{ base: 20, xs: 20, sm: 20, md: 20, lg: 20 }}
                verticalSpacing={{ base: 5 }}
              >
                <TextInput
                  label="Manufacturer"
                  placeholder="Product manufacturer e.g Pfizer"
                  {...productForm.getInputProps("manufacturer")}
                />
                <NumberInput
                  hideControls
                  label="Amount in stock"
                  required
                  {...productForm.getInputProps("stock")}
                />
              </SimpleGrid>
              <TagsInput
                label="Press Enter to submit a tag"
                leftSection={
                  <HoverCard width={280} shadow="md">
                    <HoverCard.Target>
                      <IconInfoCircle color="teal" />
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size="sm">
                        Tags allow customers to discover your products. Insert
                        tags that best describe your product e.g&nbsp;
                        <b>Antibiotics</b>
                      </Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                }
                placeholder="Tags e.g Painkiller, Analgesic, Fever, Tummy ache"
                {...productForm.getInputProps("tags")}
              />
              {formCase === "create" ? (
                <>
                  <Dropzone
                    accept={["image/png", "image/jpeg", "image/jpg"]}
                    onDrop={(e) => {
                      setNewProductImages((prevImages) => [
                        ...prevImages,
                        ...e,
                      ]);
                    }}
                  >
                    <Group
                      justify="center"
                      gap="xl"
                      mih={220}
                      style={{ pointerEvents: "none" }}
                    >
                      <Dropzone.Accept>
                        <IconUpload
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-blue-6)",
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <IconX
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-red-6)",
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <IconPhoto
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-dimmed)",
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Idle>

                      <div>
                        <Text size="xl" inline>
                          Drag images here or click to select files
                        </Text>
                        <Text size="sm" c="dimmed" inline mt={7}>
                          Attach as many files as you like, each file should not
                          exceed 5mb
                        </Text>
                      </div>
                    </Group>
                  </Dropzone>

                  {newProductImages.length === 0 ? (
                    <Alert
                      variant="light"
                      color="blue"
                      className="alert"
                      title="No Images Selected"
                      icon={<IconInfoCircle />}
                    >
                      If no images are selected, vendor logo will be displayed
                      <br />
                    </Alert>
                  ) : (
                    <Carousel
                      height={250}
                      slideSize={{ base: "100%", sm: "50%", md: "33.333333%" }}
                      slideGap={{ base: 0, sm: "md" }}
                      align="start"
                      slidesToScroll={3}
                    >
                      {newProductImages.map((file, index) => {
                        const imageUrl = URL.createObjectURL(file);
                        return (
                          <Carousel.Slide>
                            <Card className="preview-product-image-container">
                              <Card.Section>
                                <ActionIcon
                                  variant="subtle"
                                  aria-label="Settings"
                                  onClick={() => {
                                    setNewProductImages(
                                      newProductImages.filter((e) => e !== file)
                                    );
                                  }}
                                >
                                  <IconTrash
                                    style={{ width: "70%", height: "70%" }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                <Image
                                  key={index}
                                  className="preview-product-image"
                                  src={imageUrl}
                                  onLoad={() => URL.revokeObjectURL(imageUrl)}
                                />
                              </Card.Section>
                            </Card>
                          </Carousel.Slide>
                        );
                      })}
                    </Carousel>
                  )}
                </>
              ) : (
                <>
                  <Dropzone
                    // accept={IMAGE_MIME_TYPE}
                    accept={["image/png", "image/jpeg", "image/jpg"]}
                    onDrop={(e) => {
                      setNewProductImages((prevImages) => [
                        ...prevImages,
                        ...e,
                      ]);
                    }}
                  >
                    <Group
                      justify="center"
                      gap="xl"
                      mih={220}
                      style={{ pointerEvents: "none" }}
                    >
                      <Dropzone.Accept>
                        <IconUpload
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-blue-6)",
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <IconX
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-red-6)",
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <IconPhoto
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-dimmed)",
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Idle>

                      <div>
                        <Text size="xl" inline>
                          Drag images here or click to select files
                        </Text>
                        <Text size="sm" c="dimmed" inline mt={7}>
                          Attach as many files as you like, each file should not
                          exceed 5mb
                        </Text>
                      </div>
                    </Group>
                  </Dropzone>

                  {newProductImages.length === 0 &&
                  productForm.values.images.length === 0 ? (
                    <Alert
                      variant="light"
                      color="blue"
                      className="alert"
                      title="No Images Selected"
                      icon={<IconInfoCircle />}
                    >
                      You must upload at least&nbsp;
                      <b>ONE</b>&nbsp;image
                      <br />
                    </Alert>
                  ) : (
                    <Carousel
                      height={250}
                      slideSize={{ base: "100%", sm: "50%", md: "33.333333%" }}
                      slideGap={{ base: 0, sm: "md" }}
                      align="start"
                      slidesToScroll={3}
                    >
                      {productForm.values.images.map((image, index) => {
                        return (
                          <Carousel.Slide>
                            <Card className="preview-product-image-container">
                              <Card.Section>
                                <ActionIcon
                                  variant="subtle"
                                  aria-label="Settings"
                                  onClick={() => {
                                    productForm.setValues({
                                      images: productForm.values.images.filter(
                                        (i) => i !== image
                                      ),
                                    });
                                  }}
                                >
                                  <IconTrash
                                    style={{ width: "70%", height: "70%" }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                <Image
                                  key={index}
                                  className="preview-product-image"
                                  src={image}
                                />
                              </Card.Section>
                            </Card>
                          </Carousel.Slide>
                        );
                      })}
                      {newProductImages.map((file, index) => {
                        const imageUrl = URL.createObjectURL(file);
                        return (
                          <Carousel.Slide>
                            <Card className="preview-product-image-container">
                              <Card.Section>
                                <ActionIcon
                                  variant="subtle"
                                  aria-label="Settings"
                                  onClick={() => {
                                    setNewProductImages(
                                      newProductImages.filter((e) => e !== file)
                                    );
                                  }}
                                >
                                  <IconTrash
                                    style={{ width: "70%", height: "70%" }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                <Image
                                  key={index}
                                  className="preview-product-image"
                                  src={imageUrl}
                                  onLoad={() => URL.revokeObjectURL(imageUrl)}
                                />
                              </Card.Section>
                            </Card>
                          </Carousel.Slide>
                        );
                      })}
                    </Carousel>
                  )}
                </>
              )}
              <Button
                onClick={() => {
                  formCase === "create" ? CreateNewProduct() : UpdateProduct();
                }}
                loading={isLoading}
              >
                {formCase === "create" ? "Save Product" : "Update Product"}
              </Button>
            </form>
          </Modal>
        </ScrollArea>
      )}
    </>
  );
}
