import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Card, Button, Text, SimpleGrid, AppShell } from "@mantine/core";

import Loader from "../../Misc/Loader";
import {
  IconBuildingStore,
  IconUserShield,
  IconWheelchair,
} from "@tabler/icons-react";
import RecentTransactions from "../RecentTransactions";
import { AppContext } from "../../Context/AppContext";
import "./styles.scss";

export default function Overview() {
  const context = useContext(AppContext);
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const [salesPeriod, setSalesPeriod] = useState<
    "today" | "week" | "all" | string
  >("today");
  return (
    <AppShell padding="lg">
      {context && context.vendors ? (
        <Container maxWidth="xl" className="overview-container">
          <SimpleGrid
            cols={{ base: 3, xs: 3, sm: 3, md: 3, lg: 3 }}
            spacing={{ base: 10, sm: "xl" }}
            verticalSpacing={{ base: "md", sm: "xl" }}
          >
            <Card shadow="sm" radius="md" withBorder className="stat-card">
              <Card.Section>
                <SimpleGrid
                  cols={{ base: 1, xs: 1, sm: 1, md: 3, lg: 3 }}
                  spacing={{ base: 0 }}
                  verticalSpacing={{ base: 5 }}
                >
                  <IconWheelchair color="#1971c2" size={45} />
                  <div className="flex-col card-content">
                    <Text fw={400} size="15px" color="#808080">
                      Patients
                    </Text>
                    <Text
                      fw={500}
                      size="30px"
                      style={{ marginTop: "3px" }}
                      color="#333333"
                    >
                      22
                    </Text>
                    <Link to="/dashboard/patients">
                      <Button fullWidth className="btn">
                        View Patients
                      </Button>
                    </Link>
                  </div>
                </SimpleGrid>
              </Card.Section>
            </Card>
            {/*
            <Card shadow="sm" radius="md" withBorder className="stat-card">
              <Card.Section>
                <SimpleGrid
                  cols={{ base: 1, xs: 1, sm: 1, md: 3, lg: 3 }}
                  spacing={{ base: 1 }}
                  verticalSpacing={{ base: 5 }}
                >
                  <IconClipboardList color="#1971c2" size={45} />
                  <div className="flex-col card-content">
                    <Text fw={400} size="15px" color="#808080">
                      
                      Total Sales
                    </Text>
                    <Text
                      fw={500}
                      size="30px"
                      style={{ marginTop: "3px" }}
                      color="#333333"
                    >
                      ₦
                      {salesPeriod === "today"
                        ? "53,452"
                        : salesPeriod === "all"
                        ? "454,908,343"
                        : "1,232,567"}
                    </Text>
                    <Select
                      data={[
                        { value: "today", label: "Today" },
                        { value: "week", label: "Last 7 Days" },
                        { value: "all", label: "All Time" },
                      ]}
                      value={salesPeriod}
                      onChange={(e) => {
                        if (e !== null) {
                          setSalesPeriod(e);
                        }
                      }}
                    />

                   
                  </div>
                </SimpleGrid>
              </Card.Section>
            </Card>
                */}
            <Card shadow="sm" radius="md" withBorder className="stat-card">
              <Card.Section>
                <SimpleGrid
                  cols={{ base: 1, xs: 1, sm: 1, md: 3, lg: 3 }}
                  spacing={{ base: 1 }}
                  verticalSpacing={{ base: 5 }}
                >
                  <IconBuildingStore color="#1971c2" size={45} />
                  <div className="flex-col card-content">
                    <Text fw={400} size="15px" color="#808080">
                      Vendors
                    </Text>
                    <Text
                      fw={500}
                      size="30px"
                      style={{ marginTop: "3px" }}
                      color="#333333"
                    >
                      {context.vendors ? context.vendors.length : 0}
                    </Text>
                    <Link to="/dashboard/vendors">
                      <Button fullWidth className="btn">
                        Manage Vendors
                      </Button>
                    </Link>
                  </div>
                </SimpleGrid>
              </Card.Section>
            </Card>
            <Card shadow="sm" radius="md" withBorder className="stat-card">
              <Card.Section>
                <SimpleGrid
                  cols={{ base: 1, xs: 1, sm: 1, md: 3, lg: 3 }}
                  spacing={{ base: 1 }}
                  verticalSpacing={{ base: 5 }}
                >
                  <IconUserShield color="#1971c2" size={45} />
                  <div className="flex-col card-content">
                    <Text fw={400} size="15px" color="#808080">
                      Admins
                    </Text>
                    <Text
                      fw={500}
                      size="30px"
                      style={{ marginTop: "3px" }}
                      color="#333333"
                    >
                      {context.admins ? context.admins.length : 0}
                    </Text>
                    <Link to="/dashboard/admin">
                      <Button fullWidth className="btn">
                        View Admins
                      </Button>
                    </Link>
                  </div>
                </SimpleGrid>
              </Card.Section>
            </Card>
          </SimpleGrid>
          <RecentTransactions />
        </Container>
      ) : (
        <Loader />
      )}
    </AppShell>
  );
}
