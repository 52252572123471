import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  Paper,
  SimpleGrid,
  Alert,
  rem,
  Tabs,
  Button,
  Text,
  Badge,
  AppShell,
  TextInput,
  Select,
  Switch,
  Table,
  ScrollArea,
  Popover,
  Modal,
  Loader as Loadulation,
  useMantineTheme,
  Textarea,
  FileButton,
  TagsInput,
  Image,
  ActionIcon,
  HoverCard,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import {
  IconBoxSeam,
  IconBuildingStore,
  IconUserCog,
  IconCurrencyNaira,
} from "@tabler/icons-react";

import ProductsTab from "./ProductsTab";
import OutletsTab from "./OutletsTab";
import ProfileTab from "./ProfileTab";
import PayoutTab from "./PayoutTab";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";

import {
  PerformRequest,
  UploadFile,
  usePerformRequest,
} from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { Vendor } from "../../Lib/Types";
import DefaultImage from "../../Assets/IMG/DefaultProductImage.jpg";

import Loader from "../../Misc/Loader";
import { DefaultResponse, FileUploadResponse } from "../../Lib/Responses";

import "./styles.scss";

export default function SingleVendor() {
  const params = useParams();
  const { vendorID } = params;

  const {
    data: vendor,
    isLoading: isFetchingVendor,
    reloadData: reloadVendor,
  } = usePerformRequest<Vendor>({
    method: "GET",
    url: `${Endpoints.GetSingleVendor}=${vendorID}`,
  });

  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const iconStyle = { width: rem(24), height: rem(24), color: "#1971c2" };

  const [isVendorActive, setVendorActive] = useState<boolean>(false);
  const [isVendorActiveLoading, setVendorActiveLoading] =
    useState<boolean>(false);

  const ToggleVendorActivation = async () => {
    setVendorActiveLoading(true);
    const r: DefaultResponse = await PerformRequest({
      route: `${Endpoints.ToggleVendorActivation}?vendor=${vendorID}`,
      method: "PATCH",
      data: { active: !vendor?.active },
    }).catch(() => {
      setVendorActiveLoading(false);
    });
    if (r && r.data.statusCode === 200) {
      DefaultSuccessNotification("Completed");
      reloadVendor();
    }
    setVendorActiveLoading(false);
  };

  const [isLogoLoading, setLogoLoading] = useState<boolean>(false);
  const ChangeVendorLogo = async (e: File) => {
    setLogoLoading(true);
    const r1: FileUploadResponse = await UploadFile([e]).catch(() => {
      setLogoLoading(false);
      DefaultErrorNotification("Logo could not be updated! Please try again");
    });
    if (r1.data && r1.data.statusCode === 200) {
      const r2: DefaultResponse = await PerformRequest({
        method: "PUT",
        route: `${Endpoints.UpdateVendorProfile}=${vendor?.id}`,
        data: {
          firstname: vendor?.firstname,
          email: vendor?.email,
          lastname: vendor?.lastname,
          vendorname: vendor?.vendorname,
          phone: vendor?.phone.toString(),
          rate: vendor?.rate,
          department: vendor?.department,
          logo: r1.data.data[0],
        },
      }).catch(() => {
        setLogoLoading(false);
      });
      setLogoLoading(false);
      if (r2.data && r2.data.statusCode === 200) {
        DefaultSuccessNotification("Vendor logo has been changed");
        reloadVendor();
      }
    }
  };
  return (
    <AppShell padding="lg">
      {isFetchingVendor ? (
        <Container maxWidth="lg">
          <br />
          <Loader />
        </Container>
      ) : (
        <>
          {vendor ? (
            <Container maxWidth="lg" className="single-vendor-container">
              <div className="flex-row align-start justify-between width-100">
                <div className="flex-row name-row align-center">
                  <FileButton
                    onChange={(e) => {
                      ChangeVendorLogo(e as File);
                    }}
                    accept="image/png,image/jpeg"
                    disabled={isLogoLoading}
                  >
                    {(props) => (
                      <>
                        {isLogoLoading ? (
                          <div className="flex-row  align-center justify-center logo-loading">
                            <Loadulation />
                          </div>
                        ) : (
                          <div
                            className="logo"
                            {...props}
                            style={{
                              backgroundImage: `url(${
                                vendor.logo ?? DefaultImage
                              })`,
                            }}
                          ></div>
                        )}
                      </>
                    )}
                  </FileButton>

                  <div className="flex-col name-col">
                    <Text size="30px" fw={500}>
                      {vendor.vendorname}
                    </Text>
                    <Text size="15px" c="gray">
                      {vendor.email}
                    </Text>
                    <Badge size="lg">
                      <Text className="capitalize">{vendor.department}</Text>
                    </Badge>
                    <div className="flex-row align-center">
                      {/* <Text
                c={`${isVendorActive ? "green" : "red"}`}
                className="activation-status"
              >
                {isVendorActive
                  ? "Vendor is activated!"
                  : "Vendor is deactivated"}
              </Text>
              &nbsp; */}
                      <Button
                        className="toggle-activation"
                        variant="gradient"
                        gradient={
                          vendor.active
                            ? { from: "red", to: "red", deg: 90 }
                            : { from: "green", to: "green", deg: 90 }
                        }
                        onClick={ToggleVendorActivation}
                        loading={isVendorActiveLoading}
                      >
                        {vendor.active
                          ? "Deactivate Vendor"
                          : "Activate Vendor"}
                      </Button>
                    </div>
                  </div>
                </div>
                <Paper withBorder p={20}>
                  <div className="flex-col">
                    <Text fw={500} c="blue" size="33px">
                      ₦{getFinancialValueFromNumeric(vendor.balance ?? 0)}
                    </Text>
                    <Text fw={500} c="dimmed" size="sm">
                      Current Vendor Balance
                    </Text>
                  </div>
                </Paper>
              </div>

              <Tabs defaultValue="Outlets">
                <Tabs.List justify="space-between" grow>
                  <Tabs.Tab
                    value="Outlets"
                    leftSection={<IconBuildingStore style={iconStyle} />}
                  >
                    Outlets
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="Products_And_Services"
                    leftSection={<IconBoxSeam style={iconStyle} />}
                  >
                    Products and Services
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="Profile"
                    leftSection={<IconUserCog style={iconStyle} />}
                  >
                    Profile
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="Payout"
                    leftSection={<IconCurrencyNaira style={iconStyle} />}
                  >
                    Payouts
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Outlets">
                  <OutletsTab vendor={vendor} reloadVendor={reloadVendor} />
                </Tabs.Panel>

                <Tabs.Panel value="Products_And_Services">
                  <ProductsTab vendor={vendor} reloadVendor={reloadVendor} />
                </Tabs.Panel>
                <Tabs.Panel value="Profile">
                  <ProfileTab vendor={vendor} reloadVendor={reloadVendor} />
                </Tabs.Panel>
                <Tabs.Panel value="Payout">
                  <PayoutTab />
                </Tabs.Panel>
              </Tabs>
            </Container>
          ) : (
            <Container maxWidth="lg">
              <Alert title="Network Error" color="red" mt={15}>
                There was an error fetching data!
              </Alert>
            </Container>
          )}
        </>
      )}
    </AppShell>
  );
}
