import { useEffect, useState } from "react";

import { Container } from "@mui/material";

import {
  Alert,
  Popover,
  Badge,
  Text,
  Table,
  Button,
  Modal,
  Group,
  TextInput,
  NumberInput,
  Select,
  Pagination,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import { IconAt, IconPhone, IconTrash } from "@tabler/icons-react";

import States from "../../../Lib/States.json";
import { Outlet, PaginationProps, Vendor } from "../../../Lib/Types";
import Loader from "../../../Misc/Loader";
import {
  PerformRequest,
  usePerformRequest,
} from "../../../Lib/usePerformRequest";
import { Endpoints } from "../../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../../Lib/Methods";
import { DefaultResponse, PaginatedResponse } from "../../../Lib/Responses";
interface OutletsTabProps {
  vendor: Vendor;
  reloadVendor: () => Promise<void>;
}

export default function OutletsTab({ vendor, reloadVendor }: OutletsTabProps) {
  const [formCase, setFormCase] = useState<"create" | "edit">("create");
  const [
    isOutletModalOpen,
    { open: OpenOutletModal, close: CloseOutletModal },
  ] = useDisclosure();

  const [isOutletsLoading, setOutletLoading] = useState<boolean>(false);

  const [vendorPagination, setVendorPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });
  const {
    data: outlets,
    response: outletsResponse,
    isLoading: isFetchingOutlets,
    reloadData: reloadOutlets,
  } = usePerformRequest<Outlet[], PaginatedResponse<Outlet>>({
    method: "GET",
    url: `${Endpoints.GetVendorOutlets}/${vendor.id}?page=${vendorPagination.page}&limit=${vendorPagination.limit}`,
  });

  const outletForm = useForm({
    initialValues: {
      id: "",
      outletname: "",
      email: vendor.email,
      phone: vendor.phone,
      state: "",
      lga: "",
      address: "",
    },
    validate: {
      outletname: (value) =>
        value.length === 0 ? "Please enter Outlet name" : null,
      state: (value) => (value?.length === 0 ? "Please select a state" : null),
      lga: (value) => (value?.length === 0 ? "Please local government" : null),
      address: (value) =>
        value?.length === 0 ? "Please enter your address" : null,
      email: (value) =>
        !validateEmail(value) ? "Please enter a valid email" : null,
      phone: (value) =>
        value?.toString()?.length !== 10 ? "Enter a valid phone number" : null,
    },
  });
  const CreateNewOutlet = async () => {
    const { hasErrors } = outletForm.validate();

    if (!hasErrors) {
      setOutletLoading(true);
      const values = {
        email: outletForm.values.email,
        outletname: outletForm.values.outletname,
        phone: `${outletForm.values.phone.toString()}`,
        vendorId: vendor.id,
        address: {
          lga: outletForm.values.lga,
          state: outletForm.values.state,
          area: outletForm.values.address,
        },
      };
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        route: `${Endpoints.CreateNewOutlet}/${vendor.id}`,
        data: values,
      }).catch(() => {
        DefaultErrorNotification("Error: could not create outlet!");
        setOutletLoading(false);
      });
      if (r && r.data.statusCode === 201) {
        reloadVendor();
        reloadOutlets();
        DefaultSuccessNotification("Outlet created!");
      }
      setOutletLoading(false);
    }
  };
  const UpdateOutlet = async () => {
    const { hasErrors } = outletForm.validate();

    if (!hasErrors) {
      setOutletLoading(true);
      const values = {
        outletname: outletForm.values.outletname,
        email: outletForm.values.email,
        phone: `${outletForm.values.phone.toString()}`,
        address: {
          lga: outletForm.values.lga,
          state: outletForm.values.state,
          area: outletForm.values.address,
        },
      };
      const r: DefaultResponse = await PerformRequest({
        method: "PATCH",
        route: `${Endpoints.UpdateVendorOutlet}/${vendor.id}/${outletForm.values.id}`,
        data: values,
      }).catch(() => {
        DefaultErrorNotification("Error: could not update outlet!");
        setOutletLoading(false);
      });
      if (r && r.data.statusCode === 200) {
        CloseOutletModal();
        reloadVendor();
        reloadOutlets();
        DefaultSuccessNotification("Outlet updated!");
      }
      setOutletLoading(false);
    }
  };
  const DeleteOutlet = async (outletID: string) => {
    setOutletLoading(true);

    const r: DefaultResponse = await PerformRequest({
      method: "DEL",
      route: `${Endpoints.DeleteVendorOutlet}?id=${outletID}`,
    }).catch(() => {
      DefaultErrorNotification("Error: could not delete outlet!");
      setOutletLoading(false);
    });
    if (r && r.data.statusCode === 201) {
      reloadVendor();
      reloadOutlets();
      DefaultSuccessNotification("Outlet deleted!");
    }
    setOutletLoading(false);
  };

  const states = States.map((state, index) => {
    return { value: state.alias, label: state.state };
  });
  const [lgaOptions, setLgaOptions] = useState<any[]>([]);
  const [isLgaDisabled, setLgaDisabled] = useState<boolean>(false);
  useEffect(() => {
    const { state } = outletForm.values;
    setLgaOptions([]);
    setLgaDisabled(state?.length === 0 ? true : false);
    const filter = States.find((s) => s.alias === state);

    if (filter) {
      const map = filter.lgas.map((lga) => {
        return { value: lga, label: lga };
      });

      setLgaOptions(map);
    }
  }, [outletForm.values.state]);

  return (
    <Container className="outlets-tab" maxWidth="xl">
      <div className="flex-row align-center">
        <Button
          onClick={() => {
            setFormCase("create");
            OpenOutletModal();
          }}
        >
          Create New Outlet
        </Button>
        &nbsp; &nbsp;
        <Button
          variant="subtle"
          onClick={() => {
            reloadOutlets();
          }}
        >
          Refresh
        </Button>
      </div>
      <Modal
        opened={isOutletModalOpen}
        onClose={CloseOutletModal}
        centered
        title={formCase === "create" ? "Create New Outlet" : "Edit Outlet"}
      >
        <div className="outlet-form flex-col justify-between">
          <TextInput
            spellCheck={false}
            placeholder="Enter Outlet Name e.g ABC Ikeja"
            label="Outlet Name"
            disabled={isOutletsLoading}
            {...outletForm.getInputProps("outletname")}
          />
          <Group justify="space-between" gap="xs" grow>
            <TextInput
              spellCheck={false}
              label="Email"
              placeholder="Outlet contact email"
              leftSection={<IconAt size={16} />}
              disabled={isOutletsLoading}
              {...outletForm.getInputProps("email")}
            />
            <NumberInput
              disabled={isOutletsLoading}
              label="Phone number"
              placeholder="Enter company phone"
              clampBehavior="strict"
              min={0}
              leftSection={<IconPhone size={16} />}
              prefix="+(234) "
              hideControls
              {...outletForm.getInputProps("phone")}
            />
          </Group>
          <div className="flex-row width-100 justify-between">
            <Select
              searchable
              label="Select State"
              placeholder="Select your location"
              w="49%"
              data={states}
              {...outletForm.getInputProps("state")}
            />
            <Select
              searchable
              label="LGA"
              placeholder="Select LGA"
              data={lgaOptions}
              disabled={isLgaDisabled}
              w="49%"
              {...outletForm.getInputProps("lga")}
            />
          </div>
          <Textarea
            {...outletForm.getInputProps("address")}
            label="Enter address"
            placeholder="Input outlet address "
          />

          <div className="flex-row width-100 align-center justify-center">
            <Button
              onClick={() => {
                if (formCase === "create") {
                  CreateNewOutlet();
                } else {
                  UpdateOutlet();
                }
              }}
              loading={isOutletsLoading}
              fullWidth
            >
              {formCase === "create" ? "Create New" : "Update"}
              &nbsp;Outlet
            </Button>
          </div>
        </div>
      </Modal>
      {isFetchingOutlets ? (
        <Loader />
      ) : (
        <>
          {outlets && (
            <>
              {outlets.length !== 0 ? (
                <>
                  <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    layout="fixed"
                  >
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Address</Table.Th>
                        <Table.Th>Email</Table.Th>
                        <Table.Th>Phone</Table.Th>

                        <Table.Th>Actions</Table.Th>
                      </Table.Tr>
                    </Table.Tbody>
                    <Table.Tbody>
                      {outlets.map((outlet) => {
                        return (
                          <Table.Tr key={outlet.id} className="record">
                            <Table.Td>
                              <Text className="capitalize">
                                {outlet.outletname}
                              </Text>
                            </Table.Td>
                            <Table.Td>
                              <Text className="capitalize" fz="sm">
                                {outlet?.address?.lga}, {outlet?.address?.state}
                              </Text>
                            </Table.Td>

                            <Table.Td>{outlet.email}</Table.Td>
                            <Table.Td>{outlet.phone}</Table.Td>
                            <Table.Td>
                              <div className="flex-row align-center">
                                <Button
                                  variant="subtle"
                                  onClick={() => {
                                    setFormCase("edit");
                                    OpenOutletModal();

                                    outletForm.setValues({
                                      id: outlet.id,
                                      outletname: outlet.outletname,
                                      email: outlet.email,
                                      phone: outlet.phone,
                                      address: outlet?.address?.area ?? "",
                                      lga: outlet?.address?.lga ?? "",
                                      state: outlet?.address?.state ?? "",
                                    });
                                  }}
                                >
                                  Edit
                                </Button>
                                &nbsp; &nbsp;
                                <Popover
                                  width={330}
                                  position="bottom"
                                  withArrow
                                  shadow="md"
                                >
                                  <Popover.Target>
                                    <Button color="red" variant="subtle">
                                      <IconTrash size="20px" />
                                    </Button>
                                  </Popover.Target>
                                  <Popover.Dropdown>
                                    <Text fw={500} size="12.5px">
                                      Are you sure you want to delete this
                                      outlet?
                                    </Text>
                                    <Button
                                      style={{ marginTop: "10px" }}
                                      color="red"
                                      onClick={() => {
                                        DeleteOutlet(outlet.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </Popover.Dropdown>
                                </Popover>
                              </div>
                            </Table.Td>
                          </Table.Tr>
                        );
                      })}
                    </Table.Tbody>
                  </Table>
                  <Pagination
                    className="pagination"
                    my={30}
                    onChange={(e) => {
                      setVendorPagination({ ...vendorPagination, page: e });
                    }}
                    total={Math.ceil((outletsResponse?.total ?? 100) / 20)}
                  />
                </>
              ) : (
                <Container maxWidth="sm">
                  <Alert mt={30} color="#1971c2" title="No Outlets found">
                    <Text>Vendor has no outlets.</Text>
                    <Button
                      mt={10}
                      onClick={() => {
                        setFormCase("create");
                        OpenOutletModal();
                      }}
                    >
                      Create New Outlet
                    </Button>
                  </Alert>
                </Container>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}
