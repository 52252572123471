import { useEffect, useState, useContext } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Group,
  Select,
  Table,
  TextInput,
  Alert,
  Text,
  Button,
  Pagination,
  Modal,
  NumberInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";

import {
  IconInfoCircle,
  IconAt,
  IconPhone,
  IconCategory,
  IconPercentage,
  IconUserCircle,
} from "@tabler/icons-react";

import DefaultImage from "../../Assets/IMG/DefaultProductImage.jpg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import { Department, PaginationProps, Vendor } from "../../Lib/Types";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validatePhone,
} from "../../Lib/Methods";
import { DefaultResponse, PaginatedResponse } from "../../Lib/Responses";
import { AppContext } from "../../Context/AppContext";
import Loader from "../../Misc/Loader";
import { DepartmentList } from "../../Lib/DemoData";

export default function Vendors() {
  const context = useContext(AppContext);
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const [vendorPagination, setVendorPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const {
    data: vendors,
    response: vendorsResponse,
    isLoading: isLoadingVendors,
    reloadData: reloadVendors,
  } = usePerformRequest<Vendor[], PaginatedResponse<Vendor>>({
    method: "GET",

    url: `${Endpoints.GetAllVendors}?page=${vendorPagination.page}&limit=${vendorPagination.limit}&department=diagnostics`,
  });

  interface VendorFormProps {
    vendorname: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    department: Department;
    rate: number;
  }
  const createVendorForm = useForm<VendorFormProps>({
    initialValues: {
      vendorname: "",
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      department: "pharmacy",
      rate: 15,
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Please enter a valid email",
      phone: (value) =>
        !validatePhone(value) ? "Enter a valid phone number" : null,
      vendorname: (value) =>
        value.toString().length === 0 ? "Provide company name" : null,
      firstname: (value) =>
        value.toString().length === 0 ? "Provide vendor admin Firstname" : null,
      lastname: (value) =>
        value.toString().length === 0 ? "Enter vendor admin Lastname" : null,
      department: (value) =>
        value.toString().length === 0 ? "Select company department" : null,
      rate: (value) => (value <= 0 ? "Enter rate for vendor" : null),
    },
  });

  const [isCreatingVendor, setCreatingVendor] = useState<boolean>(false);
  const [opened, { open: OpenCreateVendorModal, close: CloseVendorModal }] =
    useDisclosure(false);

  const CreateNewVendor = async () => {
    const { hasErrors } = createVendorForm.validate();
    if (context && !hasErrors) {
      setCreatingVendor(true);
      const {
        email,
        firstname,
        lastname,
        rate,
        phone,
        department,
        vendorname,
      } = createVendorForm.values;
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        data: {
          email,
          department,
          vendorname,
          firstname,
          lastname,
          rate,
          phone: phone.toString(),
        },
        route: Endpoints.OnboardVendor,
      }).catch(() => {
        DefaultErrorNotification();
        setCreatingVendor(false);
      });
      setCreatingVendor(false);
      if (r && r.data && r.data.statusCode === 201) {
        CloseVendorModal();
        reloadVendors();
        DefaultSuccessNotification(r.data.msg ?? "Successfully onboarded!");
      }
    }
  };
  return (
    <>
      {context ? (
        <Container maxWidth="xl" className="vendors-container">
          {!isLoadingVendors ? (
            <>
              <div className="flex-row align-center justify-between width-100">
                <Text fw={500} size="25px">
                  Vendors
                </Text>
                <Button onClick={OpenCreateVendorModal}>Add New Vendor</Button>
              </div>
              <br />
              {vendors?.length === 0 || !vendors ? (
                <div className="flex-row align-center width-100 justify-center">
                  <Alert
                    variant="light"
                    color="blue"
                    className="alert"
                    title="No Vendors Found"
                    icon={<IconInfoCircle />}
                  >
                    There are no vendors on this platform. You need to register
                    vendors using their&nbsp;
                    <b>email address</b>
                    <br />
                    <br />
                    <Button onClick={OpenCreateVendorModal}>
                      Create a Vendor
                    </Button>
                  </Alert>
                </div>
              ) : (
                <>
                  <Table verticalSpacing="xs">
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Company Name</Table.Th>
                        <Table.Th>Logo</Table.Th>
                        <Table.Th>Department</Table.Th>
                        <Table.Th>User Name</Table.Th>
                        <Table.Th>Phone</Table.Th>
                        <Table.Th>Email</Table.Th>
                        <Table.Th>View Details</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {vendors?.map((vendor, index) => {
                        return (
                          <Table.Tr key={index}>
                            <Table.Td>{vendor.vendorname}</Table.Td>
                            <Table.Td>
                              <img
                                className="table-logo"
                                src={vendor.logo ?? DefaultImage}
                                alt=""
                              />
                            </Table.Td>
                            <Table.Td>{vendor.department}</Table.Td>
                            <Table.Td>
                              {vendor.firstname} {vendor.lastname}
                            </Table.Td>
                            <Table.Td>+234 {vendor.phone}</Table.Td>
                            <Table.Td>{vendor.email}</Table.Td>

                            <Table.Td>
                              <Link to={`/dashboard/vendor/${vendor.id}`}>
                                <Button variant="subtle">View Details</Button>
                              </Link>
                            </Table.Td>
                          </Table.Tr>
                        );
                      })}
                    </Table.Tbody>
                  </Table>
                  <Pagination
                    className="pagination"
                    my={30}
                    onChange={(e) => {
                      setVendorPagination({ ...vendorPagination, page: e });
                    }}
                    total={Math.ceil((vendorsResponse?.total ?? 100) / 20)}
                  />
                </>
              )}
              <br />

              <Modal
                opened={opened}
                onClose={CloseVendorModal}
                title="Create a Vendor"
                centered
                size="lg"
              >
                <form className="flex-col create-vendor">
                  <Group justify="space-between" gap="xs" grow>
                    <TextInput
                      label="First Name"
                      placeholder="User's first name"
                      leftSection={<IconUserCircle size={16} />}
                      disabled={isCreatingVendor}
                      {...createVendorForm.getInputProps("firstname")}
                    />
                    <TextInput
                      disabled={isCreatingVendor}
                      label="Last Name"
                      placeholder="User's last name"
                      {...createVendorForm.getInputProps("lastname")}
                    />
                  </Group>
                  <TextInput
                    placeholder="Enter Company Name e.g ABC Pharmacy"
                    label="Company Name"
                    disabled={isCreatingVendor}
                    {...createVendorForm.getInputProps("vendorname")}
                  />

                  <Group justify="space-between" gap="xs" grow>
                    <TextInput
                      label="Email Address"
                      placeholder="Enter company email"
                      leftSection={<IconAt size={16} />}
                      disabled={isCreatingVendor}
                      {...createVendorForm.getInputProps("email")}
                    />
                    <NumberInput
                      hideControls
                      disabled={isCreatingVendor}
                      prefix="+234 "
                      label="Company Phone"
                      min={0}
                      maxLength={15}
                      placeholder="Enter company phone"
                      clampBehavior="strict"
                      leftSection={<IconPhone size={16} />}
                      {...createVendorForm.getInputProps("phone")}
                    />
                  </Group>

                  <Select
                    label="Department"
                    classNames={{
                      option: "capitalize",
                      label: "capitalize",
                    }}
                    placeholder="Select vendor department"
                    leftSection={<IconCategory size={16} />}
                    data={DepartmentList.map((dept) => {
                      return { value: dept.value, label: `${dept.name}` };
                    })}
                    disabled={isCreatingVendor}
                    {...createVendorForm.getInputProps("department")}
                  />
                  <NumberInput
                    label="Rate"
                    placeholder="Enter Rate"
                    clampBehavior="strict"
                    min={0}
                    leftSection={<IconPercentage size={16} />}
                    hideControls
                    disabled={isCreatingVendor}
                    {...createVendorForm.getInputProps("rate")}
                  />
                  <Button onClick={CreateNewVendor} loading={isCreatingVendor}>
                    Create Vendor
                  </Button>
                </form>
              </Modal>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
}
