import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  ComboboxItem,
  Select,
  Menu,
  Button,
  Text,
  Timeline,
  AppShell,
  ThemeIcon,
  Overlay,
  Alert,
} from "@mantine/core";

import {
  IconCurrencyNaira,
  IconInfoCircle,
  IconSparkles,
  IconThumbDown,
  IconThumbUp,
} from "@tabler/icons-react";
import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import { Action, ActionType, PatientActionType } from "../../Lib/Types";
import { getFullDate } from "../../Lib/Methods";

export default function Activity() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/dashboard");
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const getActionUser = (action: Action) => {
    if (
      action.type === "patient_activation" ||
      action.type === "patient_deactivation"
    ) {
      return (
        <Text size="sm">
          Patient:
          <Link className="link" to={`/dashboard/`}>
            {action.users.patient.name}
          </Link>
        </Text>
      );
    }

    if (
      action.type === "product_activation" ||
      action.type === "product_deactivation"
    ) {
      return (
        <Text size="sm">
          Product:
          <Link className="link" to={`/dashboard/`}>
            {action.users.product.productName}
          </Link>
        </Text>
      );
    }
    if (
      action.type === "vendor_onboarding" ||
      action.type === "vendor_deactivation" ||
      action.type === "vendor_activation"
    ) {
      return (
        <Text size="sm">
          Vendor:
          <Link className="link" to={`/dashboard/`}>
            {action.users.vendor.vendorname}
          </Link>
        </Text>
      );
    }
  };

  const getActionIcon = (type: ActionType) => {
    if (
      [
        "patient_activation",
        "product_activation",
        "vendor_activation",
      ].includes(type)
    ) {
      return (
        <ThemeIcon
          size={30}
          variant="gradient"
          gradient={{ from: "lime", to: "cyan" }}
          radius="xl"
        >
          <IconThumbUp size={20} color="white" />
        </ThemeIcon>
      );
    } else if (
      [
        "patient_deactivation",
        "product_deactivation",
        "vendor_deactivation",
      ].includes(type)
    ) {
      return (
        <ThemeIcon
          size={30}
          variant="gradient"
          gradient={{ from: "red", to: "red" }}
          radius="xl"
        >
          <IconThumbDown size={20} color="white" />
        </ThemeIcon>
      );
    } else if (type === "vendor_payout") {
      return <IconCurrencyNaira size={12} />;
    } else if (type === "vendor_onboarding") {
      return (
        <ThemeIcon
          size={30}
          variant="gradient"
          gradient={{ from: "blue", to: "blue" }}
          radius="xl"
        >
          <IconSparkles size={20} color="white" />
        </ThemeIcon>
      );
    }
  };
  const actions: Action[] = [
    {
      timestamp: Date.now(),
      type: "patient_activation",
      users: {
        patient: { patientID: "1223", name: "Hugh Jazz" },
        admin: { name: "Jon Snow", adminID: "12323" },
      },
    },
    {
      timestamp: Date.now(),
      type: "product_activation",
      users: {
        product: {
          productID: "3432",
          vendorID: "1223",
          productName: "Semi Demi Quaver",
        },
        admin: { name: "Jon Snow", adminID: "12323" },
      },
    },
    {
      timestamp: Date.now(),
      type: "product_deactivation",
      users: {
        product: {
          productID: "3432",
          vendorID: "1223",
          productName: "Premium juice 40L",
        },
        admin: { name: "Ade Lummer", adminID: "12323" },
      },
    },
    {
      timestamp: Date.now(),
      type: "vendor_onboarding",
      users: {
        vendor: {
          vendorname: "Synlab Nigeria",
          vendorID: "1223",
        },
        admin: { name: "Ade Lummer", adminID: "12323" },
      },
    },
  ];
  const icon = <IconInfoCircle />;
  return (
    <Container maxWidth="xl" className="activity-container">
      <Text fw={500} size="25px" mb={5}>
        Activity
      </Text>
      <Text fw={400} c="dimmed" size="15px">
        View all activity performed by Admins on the platform
      </Text>

      {/* <Timeline bulletSize={30} lineWidth={2} mt={30}>
        {actions.map((action, index) => {
          return (
            <Timeline.Item
              bullet={getActionIcon(action.type)}
              title={action.type.replaceAll("_", " ")}
              style={{
                textTransform: "capitalize",
              }}
            >
              <div className="flex-col">
                <>{getActionUser(action)}</>
                <Text size="sm">Admin: {action.users.admin.name}</Text>
                <Text c="dimmed" size="xs" mt={4}>
                  {getFullDate(action.timestamp)}
                </Text>
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline> */}
      <Container maxWidth="sm" className="activity-container">
        <Alert variant="light" color="blue" title="Coming Soon" icon={icon}>
          <Text>This feature is still in development</Text>
        </Alert>
      </Container>
    </Container>
  );
}
