import { Link, useNavigate } from "react-router-dom";

import { Anchor, Group, Text, AppShell, rem } from "@mantine/core";

import classes from "./Footer.module.css";
import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";

const links = [
  { link: "/dashboard/account", label: "Profile" },
  { link: "/dashboard/transactions", label: "Transactions" },
  { link: "/dashboard/admin", label: "Admins" },
  { link: "/dashboard/vendors", label: "Vendors" },
];

export function Footer() {
  const navigate = useNavigate();
  const items = links.map((link) => (
    <Anchor
      c="dimmed"
      key={link.label}
      href={link.link}
      lh={1}
      //   onClick={(event) => event.preventDefault()}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <>
      <br />
      <br />
      <br />
      <AppShell withBorder={false} className="footer-container">
        <AppShell.Footer style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <div className={classes.footer}>
            <div className={classes.inner}>
              <Text c="dimmed" fw={500} className="copyright">
                &copy;&nbsp;{new Date().getFullYear()}, Quickhealth
              </Text>
            </div>
          </div>
        </AppShell.Footer>
      </AppShell>
    </>
  );
}
