import { useEffect, useState, useContext } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import {
  Card,
  Select,
  Table,
  TextInput,
  Alert,
  Text,
  Button,
  AppShell,
  Modal,
  NumberInput,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconClock } from "@tabler/icons-react";

import "./styles.scss";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getFullDate,
  validatePhone,
} from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { DefaultResponse } from "../../Lib/Responses";
import Loader from "../../Misc/Loader";

export default function Account() {
  const context = useContext(AppContext);

  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const accountForm = useForm({
    initialValues: {
      email: "",
      firstname: "",
      phone: "",
      lastname: "",
      role: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Please enter a valid email",
      firstname: (value) => (value.length === 0 ? "Enter first name" : null),
      lastname: (value) => (value.length === 0 ? "Enter last name" : null),
      phone: (value) =>
        !validatePhone(value) ? "Enter a valid phone number" : null,
    },
  });

  useEffect(() => {
    if (context && context.profile) {
      const { firstname, phone, lastname, email, role } = context.profile;
      accountForm.setValues({
        firstname,
        phone,
        lastname,
        email,
        role,
      });
    }
  }, [context]);

  const gridStatsProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const UpdateProfile = async () => {
    const { hasErrors: isError } = accountForm.validate();
    if (!isError && context && context.profile) {
      const { email, firstname, lastname, phone } = accountForm.values;
      setLoading(true);
      const r: DefaultResponse = await PerformRequest({
        method: "PUT",
        route: Endpoints.UpdateProfile,
        data: {
          email,
          firstname,
          lastname,
          phone: `${phone.toString()}`,
          active: context.profile.active,
        },
      }).catch(() => {
        DefaultErrorNotification("An error occurred");
        setLoading(false);
      });
      setLoading(false);
      if (r && r.data && r.data.statusCode === 200) {
        DefaultSuccessNotification("Profile updated");
        context.reloadProfile();
      }
    }
  };
  return (
    <Container maxWidth="lg" className="account-container">
      {context && context.profile && !context.isLoadingProfile ? (
        <>
          <div className="flex-row justify-between width-100">
            <div className="flex-col justify-between width-100">
              <Text fw={500} size="20px" mb={5}>
                Admin Details
              </Text>
              <div className="flex-row align-center">
                <IconClock color="grey" size={20} />
                <Text c="dimmed">&nbsp; Created On: &nbsp;</Text>
                <Text fw={500}>{getFullDate(context.profile.createdAt)}</Text>
              </div>
              {/* <div className="flex-row align-center">
              <IconUserShield color="grey" size={20} />
              <Text c="dimmed">&nbsp; Created By &nbsp;</Text>
              <Link to={`/dashboard/admin/${2}`}>
                <Text c="blue" fw={500}>
                  Jon Jones
                </Text>
              </Link>
            </div> */}
            </div>
          </div>
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              UpdateProfile();
            }}
          >
            <Grid container spacing={2}>
              <Grid item {...gridStatsProps}>
                <TextInput
                  {...accountForm.getInputProps("firstname")}
                  placeholder="First Name"
                  label="First Name"
                />
              </Grid>
              <Grid item {...gridStatsProps}>
                <TextInput
                  {...accountForm.getInputProps("lastname")}
                  placeholder="Last Name"
                  label="Last Name"
                />
              </Grid>
              <Grid item {...gridStatsProps}>
                <TextInput
                  {...accountForm.getInputProps("email")}
                  placeholder="Email Address"
                  label="Email Address"
                />
              </Grid>
              <Grid item {...gridStatsProps}>
                <NumberInput
                  hideControls
                  prefix="+234 "
                  min={0}
                  maxLength={15}
                  {...accountForm.getInputProps("phone")}
                  placeholder="Phone"
                  label="Phone"
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <Select
                  placeholder="Select Role"
                  label="Select Role"
                  {...accountForm.getInputProps("role")}
                  disabled
                  data={[
                    { label: "Admin", value: "ADMIN" },
                    { label: "Sub Admin", value: "SUBADMIN" },
                  ]}
                />
              </Grid>
            </Grid>
            <br />
            <Button fullWidth type="submit" loading={isLoading}>
              Save Profile
            </Button>
          </form>
          <br />
        </>
      ) : (
        <>
          <br />
          <Loader />
        </>
      )}
    </Container>
  );
}
