import { createContext, useContext } from "react";
import { Admin, Role, Vendor } from "../Lib/Types";

export interface ContextProps {
  profile: Admin;
  isLoadingProfile: boolean;
  reloadProfile: () => void;

  admins: Admin[];
  isFetchingAdmins: boolean;

  vendors: Vendor[];
  isLoadingVendors: boolean;
  reloadVendors: () => void;

  logoutAdmin: () => void;
}
const AppContext = createContext<ContextProps | null>(null);

export { AppContext };
