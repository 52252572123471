import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { DefaultErrorNotification } from "./Methods";

type VerifyAccountProps = {
  account_number: string;
  bank_code: string;
};
export const VerifyAccountNumber = async ({
  account_number,
  bank_code,
}: VerifyAccountProps) => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`,
    headers: {
      Authorization: "Bearer sk_test_3487961b86f87d2d5d3322c07973fc2a0ef5db4e",
    },
  };
  const r = await axios(config).catch((err: AxiosError) => {
    const data = err.response?.data as unknown as any;
    DefaultErrorNotification(data.message);
  });
  // if (r.request.status !== 200) {
  // }
  return r;
};
